import React, { Fragment, PureComponent } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import { Heading } from '@nbc-design/heading';
import { getPaymentInformationSlice } from '../selectors';
import { PaymentDetailSlice, PaymentSlice } from '../types';
import { getTextFactory } from 'utils/TextUtils';
import { InjectedIntl, injectIntl } from 'react-intl';
import SummarySection from './SummarySection';
import { BFF_STEPS_IDS } from 'utils/stepsConstants';
import SummaryField from './SummaryField';
import formatCurrency from 'utils/formatCurrency';
import { ACCOUNT_TYPE, ACCOUNTS_WITH_PROVINCE } from 'containers/AccountsStep1/constants';

export type PaymentDetailSectionPropsFromState = {
  paymentDetailSlice: PaymentDetailSlice;
};

export type PaymentDetailSectionSectionProps = {
  goToStep: (stepId: string) => string;
  intl: InjectedIntl;
};

export class PaymentDetailSection extends PureComponent<
  PaymentDetailSectionSectionProps & PaymentDetailSectionPropsFromState
> {
  getGlobalText = getTextFactory(this.props.intl, 'global');
  getPaymentDetailText = getTextFactory(this.props.intl, 'paymentInformation');
  getSummaryText = getTextFactory(this.props.intl, 'summary.paymentInformation');

  renderContent = (): JSX.Element | null => {
    const { paymentDetailSlice, intl } = this.props;
    const { locale } = intl;

    if (!paymentDetailSlice) {
      return null;
    }

    const payments: PaymentSlice[] = paymentDetailSlice.payments;

    return (
      <div className="payment-information-recap-content">
        {payments &&
          payments.map((payment: PaymentSlice) => {
            const {
              accountType,
              currency,
              frequencyCd,
              startMonth,
              dayOfMonth,
              amount,
              referenceAgeTypeCd,
              transferModeCd,
              paymentTypeCd,
              province,
            }: PaymentSlice = payment;

            return (
              <Fragment key={btoa(JSON.stringify(payment))}>
                <Row>
                  <Col xs={12} md={12} xl={12}>
                    <Heading type="h3" size={5} data-test="payment_account">
                      {`${accountType?.[locale]}${
                        ACCOUNTS_WITH_PROVINCE.includes(accountType?.code as ACCOUNT_TYPE) && province
                          ? ' ' + province?.[intl.locale]
                          : ''
                      } ${currency?.[locale]}`}
                    </Heading>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} xl={6}>
                    <SummaryField dataTest="payment_frequency" label={this.getPaymentDetailText('frequency')}>
                      {frequencyCd?.[locale]}
                    </SummaryField>
                  </Col>
                  <Col xs={12} md={6} xl={6}>
                    <SummaryField dataTest="payment_startDate" label={this.getPaymentDetailText('startDate')}>
                      {startMonth?.[locale]}
                    </SummaryField>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={12} xl={12}>
                    <SummaryField dataTest="payment_paymentDate" label={this.getPaymentDetailText('paymentDate')}>
                      {dayOfMonth}
                    </SummaryField>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={12} xl={12}>
                    <SummaryField dataTest="payment_desiredAmount" label={this.getPaymentDetailText('desiredAmount')}>
                      {amount ? formatCurrency(amount, currency?.[locale], locale) : paymentTypeCd?.[locale]}
                    </SummaryField>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={12} xl={12}>
                    <SummaryField
                      dataTest="payment_minimumDeterminedBy"
                      label={this.getPaymentDetailText('minimumDeterminedBy')}
                    >
                      {referenceAgeTypeCd?.[locale]}
                    </SummaryField>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={12} xl={12}>
                    <SummaryField dataTest="payment_receiveBy" label={this.getPaymentDetailText('receiveBy')}>
                      {transferModeCd?.[locale]}
                    </SummaryField>
                  </Col>
                </Row>
              </Fragment>
            );
          })}
      </div>
    );
  };

  render() {
    const { goToStep, intl, paymentDetailSlice } = this.props;

    return (
      <SummarySection
        intl={intl}
        name="payment"
        title={this.getSummaryText('pageTitle')}
        stepId={BFF_STEPS_IDS.PAYMENTINFORMATION}
        goToStep={goToStep}
        renderSectionContent={this.renderContent}
        editable={paymentDetailSlice?.editable}
      />
    );
  }
}

const mapStateToProps = (state: PaymentDetailSlice) => {
  return {
    paymentDetailSlice: getPaymentInformationSlice(state),
  };
};

export default connect<PaymentDetailSectionPropsFromState, {}, PaymentDetailSection>(mapStateToProps)(
  injectIntl(PaymentDetailSection),
);
