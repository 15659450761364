import React, { FC } from 'react';

import {
  createErrorFormatter,
  createAddressErrorFormatter,
  createMainOccupationAddressErrorFormatter,
} from 'utils/productFormUtils';
import { getTextFactory } from 'utils/TextUtils';
import { ProductFormAddressSelect } from 'components/productFormFields';
import ManualAddressForm from './components';
import { AddressInputProps } from './types';

const AddressInput: FC<AddressInputProps> = ({
  name,
  intl,
  textPrefix,
  helpText,
  label,
  allRefData,
  isReadOnly,
  isShowApt = true,
  errorLabel,
  change,
  placeHolder = 'addressSearch',
}) => {
  const getGlobalText = getTextFactory(intl, 'global');
  const getText = getTextFactory(intl, textPrefix);

  const formatErrorMessage = (prefix?: string, suffix?: string) =>
    prefix === 'address'
      ? createAddressErrorFormatter(intl)
      : prefix === 'addressMainOccupation'
      ? createMainOccupationAddressErrorFormatter(intl)
      : createErrorFormatter(intl, prefix, suffix);

  return (
    <>
      <ProductFormAddressSelect
        name={name}
        allRefData={allRefData}
        label={label}
        helpText={helpText ? getText(helpText) : ''}
        placeholder={getGlobalText(placeHolder)}
        isShowApt={isShowApt}
        formatErrorMessage={formatErrorMessage(errorLabel)}
      />

      <ManualAddressForm
        name={name}
        allRefData={allRefData}
        isReadOnly={isReadOnly}
        helpText={helpText ? getText(helpText) : ''}
        change={change}
        formatErrorMessage={formatErrorMessage()}
      />
    </>
  );
};

export default AddressInput;
