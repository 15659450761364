import React, { PureComponent, ChangeEvent, FocusEvent } from 'react';
import { ProductFormField } from 'bnc-react-forms';
import { Row, Col } from 'react-flexbox-grid';
import { phoneNumberFormatter } from 'utils';
import { createAddressErrorFormatter, createErrorFormatter } from 'utils/productFormUtils';
import { EventData } from 'types/interfaces';
import { getTextFactory } from 'utils/TextUtils';
import { ProductFormSelect, ProductFormInput } from '..';

export type productFormPhoneInputProps = ProductFormField.componentPropTypes & {
  helpText: string;
  tooltip: string;
  placeholder: string;
  inline: boolean;
  className: string;
  isLoading: boolean;
  disablePhoneType?: boolean;
  disablePhone?: boolean;
  handleOnBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  handleOnChange?: (event: ChangeEvent<HTMLInputElement>, isValid?: boolean) => void;
};

class ProductFormPhoneInput extends PureComponent<productFormPhoneInputProps> {
  getText = getTextFactory(this.props.intl, 'personalInformation');
  getAccessibilityText = getTextFactory(this.props.intl, 'accessibility.label');

  handlePhoneNumberChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();

    const { change } = this.props;
    const { name, value }: EventData = event.target;
    change(name, phoneNumberFormatter(value));
  };

  handleOnChangeInput = (event: ChangeEvent<HTMLInputElement>, isValid?: boolean) => {
    const { handleOnChange } = this.props;
    if (handleOnChange) {
      handleOnChange(event, isValid);
    }
  };

  render() {
    const { name, intl, refData, disablePhoneType, disablePhone, handleOnBlur } = this.props;

    const formatErrorMessage = (prefix?: string, suffix?: string) =>
      prefix === 'address' ? createAddressErrorFormatter(intl) : createErrorFormatter(intl, prefix, suffix);

    return (
      <Row className="product-form-phone-input">
        <Col xs={12} md={4} lg={4}>
          <ProductFormSelect
            name={`${name}.telephoneType`}
            refData={refData}
            formatErrorMessage={formatErrorMessage('personalInformation', 'telephoneType')}
            placeholder={this.getText('phoneTypePlaceholder')}
            disabled={disablePhoneType}
            ariaLabel={this.getText('phoneTypePlaceholder')}
          />
        </Col>
        <Col xs={12} md={8} lg={8}>
          <ProductFormInput
            name={`${name}.phoneNumber`}
            formatErrorMessage={formatErrorMessage('personalInformation', 'phoneNumber')}
            onChange={this.handlePhoneNumberChange}
            disabled={disablePhone}
            handleOnBlur={handleOnBlur}
            handleOnChange={this.handleOnChangeInput}
            ariaLabel={this.getAccessibilityText('phoneNumber')}
          />
        </Col>
      </Row>
    );
  }
}

export default ProductFormPhoneInput;
