import trimStart from 'lodash/trimStart';
import trim from 'lodash/trim';
import { SocialInsuranceNumber } from 'social-insurance-number';
import { COUNTRIES, ENV, LANG, LANG_DEFAULT, RECAPTCHA_KEY, URLS } from './constants';
import { InjectedIntl } from 'react-intl';
import { ErrorMessage, ProductForm, RefData, Values } from 'types/interfaces';
import { areaCodeRegex } from './regex';

export enum CHANNELS {
  SBIP = 'sbip',
  BRANCH = 'branch',
  VSD = 'vsd',
  BNC = 'bnc',
}

export enum INCOME_STEP_CONSTANTS {
  FULL_TIME_EMPLOYEE = '20',
  UNEMPLOYED = '26',
  JOB_TITLE_STUDENT = 'Z028',
  JOB_TITLE_UNEMPLOYED = 'Z027',
  JOB_TITLE_RETIRED = 'Z029',
  MASTERCARD_WORLD_ELITE = 'MC-WE',
  MASTERCARD_WORLD = 'MC-WO',
}

export enum VSD_CONSENT_STEPS {
  INTRO = 'intro',
  QUEBEC_RESIDENT = 'quebecResident',
  EXCLUSIONS = 'exclusions',
  DELAYS = 'delays',
  PERSONAL_INFO = 'personalInfo',
  CONCLUSION = 'conclusion',
  CONFIRMATION = 'confirmation',
}

export enum CHANNEL_TYPES {
  ASSISTED = 'assisted',
  UNASSISTED = 'unassisted',
}

export const VSD_CONSENT_STEPS_SEQUENCE = [
  VSD_CONSENT_STEPS.INTRO,
  VSD_CONSENT_STEPS.QUEBEC_RESIDENT,
  VSD_CONSENT_STEPS.EXCLUSIONS,
  VSD_CONSENT_STEPS.DELAYS,
  VSD_CONSENT_STEPS.PERSONAL_INFO,
  VSD_CONSENT_STEPS.CONCLUSION,
  VSD_CONSENT_STEPS.CONFIRMATION,
];

export const WHITELISTED_QUERY_PARAMS = ['packageId', 'productId', 'promoCd', 'quotationGuid', 'tacticId'];

export const DEFAULT_CHANNEL = CHANNELS.SBIP;
export const ASSISTED_CHANNELS = [CHANNELS.BRANCH, CHANNELS.VSD];
export const UNASSISTED_CHANNELS = [CHANNELS.SBIP, CHANNELS.BNC];

export const isAssisted = (channel: CHANNELS): boolean => ASSISTED_CHANNELS.includes(channel);
export const isUnassisted = (channel: CHANNELS): boolean => UNASSISTED_CHANNELS.includes(channel);

export const getChannelType = (channel: CHANNELS): CHANNEL_TYPES | null => {
  if (isAssisted(channel)) {
    return CHANNEL_TYPES.ASSISTED;
  } else if (isUnassisted(channel)) {
    return CHANNEL_TYPES.UNASSISTED;
  }
  return null;
};

// Parse a date with support for the SAP short format "YYYY-MM-DD"
export const parseDate = (value: string): Date => {
  const isShortDate = typeof value === 'string' && value.length === 10;
  return isShortDate ? new Date(`${value}T12:00:00`) : new Date(value || Date.now());
};

// Returns a function for formatting error messages with multiple fallbacks
export const createErrorFormatter = (intl: InjectedIntl, prefix: string, defaultsPrefix: string) => (
  error: ErrorMessage,
  {
    input,
    meta,
    channel,
    channelType,
  }: { input: { name: string }; meta: { schema?: any }; channel: string; channelType: string },
): string => {
  let messageId: string = '';

  const hasValidMessage = () => messageId && messageId in intl.messages;

  if (channel) {
    messageId = `${prefix}.${input.name}.error.${error.type}.${channel}`;
  }

  if (channelType && !hasValidMessage()) {
    messageId = `${prefix}.${input.name}.error.${error.type}.${channelType}`;
  }

  if (!hasValidMessage()) {
    messageId = `${prefix}.${input.name}.error.${error.type}`;
  }

  if (!hasValidMessage()) {
    messageId = `${defaultsPrefix}.${error.type}`;
  }

  return intl.formatMessage(
    {
      id: messageId,
      defaultMessage: error.message,
    },
    { ...error, ...meta.schema },
  );
};

export const normalizeEmail = (value: string): string => trim(value.toLowerCase());

export const socialInsuranceFormatter = (value: string = ''): string => {
  const newValue = trimStart(value.replace(/[^\d]/g, '')).slice(0, 9);
  const match1 = newValue.match(/^\s*(\d{3})(\d{3})(\d+)\s*$/);
  const match2 = newValue.match(/^\s*(\d{3})(\d+)\s*$/);
  const [, a = '', b = '', c = ''] = match1 || match2 || [];

  if (match1) {
    return `${a} ${b} ${c}`;
  } else {
    return match2 ? `${a} ${b}` : newValue;
  }
};

export const validSocialInsurance = (value: string = ''): boolean => {
  const sin = new SocialInsuranceNumber(value.replace(/ /g, ''));
  return sin.isValid();
};

export const isObfuscatedText = (value: string = ''): boolean => {
  return value.startsWith('*');
};

export const ssnFormatter = (value: string = ''): string => {
  const newValue = trimStart(value.replace(/[^\d]/g, '')).slice(0, 9);
  const match1 = newValue.match(/^\s*(\d{3})(\d{2})(\d+)\s*$/);
  const match2 = newValue.match(/^\s*(\d{3})(\d+)\s*$/);
  const [, a = '', b = '', c = ''] = match1 || match2 || [];

  if (match1) {
    return `${a}-${b}-${c}`;
  } else {
    return match2 ? `${a}-${b}` : newValue;
  }
};

export const phoneNumberFormatter = (value: string = ''): string => {
  const newValue = trimStart(value.replace(/[^\d]/g, '')).slice(0, 10);
  const match1 = newValue.match(/^\s*(\d{3})(\d{3})(\d+)\s*$/);
  const match2 = newValue.match(/^\s*(\d{3})(\d+)\s*$/);
  const [, a = '', b = '', c = ''] = match1 || match2 || [];

  if (match1) {
    return `${a}-${b}-${c}`;
  } else {
    return match2 ? `${a}-${b}` : newValue;
  }
};

export const areaCodePhoneNumberFormatter = (value: string = '', country: string = ''): string => {
  const phoneNumberValue = value.split(' ');
  const areaCodeValue = phoneNumberValue[0];
  const phoneNumber = phoneNumberValue[1];

  if (areaCodeValue.match(areaCodeRegex)) {
    const newValue =
      country === COUNTRIES.CA || country === COUNTRIES.US
        ? trimStart(phoneNumber.replace(/[^\d]/g, '')).slice(0, 10)
        : trimStart(phoneNumber.replace(/[^\d]/g, ''));

    const match1 = newValue.match(/^\s*(\d{3})(\d{3})(\d+)\s*$/);
    const match2 = newValue.match(/^\s*(\d{3})(\d+)\s*$/);
    const [, a = '', b = '', c = ''] = match1 || match2 || [];

    if (match1 !== null && newValue.length >= 7 && newValue.length <= 10) {
      return `${areaCodeValue} ${a}-${b}-${c}`;
    } else {
      return `${areaCodeValue} ${newValue}`;
    }
  }
  return '';
};

export const sortPhoneAreaCodesByCountryName = (countryRefData: RefData[]): RefData[] => {
  const usCaData = countryRefData
    .filter((data) => data.valueDomainCode === 'CA' || data.valueDomainCode === 'US')
    .sort();
  const filterCountry = countryRefData.filter(
    (data) => !(data.valueDomainCode === 'CA' || data.valueDomainCode === 'US'),
  );

  const locale = loadLocale();

  filterCountry.sort((a, b) =>
    locale === 'en'
      ? a.en.label.localeCompare(b.en.label, locale, { sensitivity: 'base' })
      : a.fr.label.localeCompare(b.fr.label, locale, { sensitivity: 'base' }),
  );

  filterCountry.unshift(...usCaData);

  return filterCountry;
};

export enum DECISION_RESULTS {
  APPROVED = 'approved',
  DECLINED = 'declined',
  DOWNSELL = 'downsell',
  GREYZONE = 'greyzone',
}

export const getDecisionResult = (productForm: ProductForm): DECISION_RESULTS => {
  const { isApproved, isDeclined, isDownsell } = productForm.values as Values;

  if (isApproved) {
    return DECISION_RESULTS.APPROVED;
  } else if (isDeclined) {
    return DECISION_RESULTS.DECLINED;
  } else if (isDownsell) {
    return DECISION_RESULTS.DOWNSELL;
  }

  return DECISION_RESULTS.GREYZONE;
};

export const isObject = (val: object): boolean => {
  return val != null && typeof val === 'object' && Array.isArray(val) === false;
};

export const saveItem = (id: string = '', value: string = ''): void => {
  localStorage.setItem(id, value);
};

export const loadItem = (id: string): string | null => {
  return localStorage.getItem(id);
};

export const deleteItem = (id: string): void => {
  return localStorage.removeItem(id);
};

export const saveLocale = (locale: string): void => {
  const locales = Object.values(LANG);
  if (locales.includes(locale)) {
    saveItem('locale', locale);
  }
};

export const loadLocale = (): string => {
  return loadItem('locale') || LANG_DEFAULT;
};

export const getCaptchaResponse = (): string | null => {
  return loadItem(RECAPTCHA_KEY);
};

export const saveCaptchaResponse = (token: string) => {
  saveItem(RECAPTCHA_KEY, token);
};

export const removeCaptchaResponse = () => {
  deleteItem(RECAPTCHA_KEY);
};

/**
 * Parses the hash of a url and return an array of key/value tuples.
 * @param locationHash The 'hash' part of a url, including the # sign
 */
export const parseLocationHash = (locationHash: string): { [key: string]: string } =>
  String(locationHash)
    .replace(/^#/, '')
    .split('&')
    .filter((item) => !!item)
    .reduce((hash, item) => {
      const [key, value = ''] = item.split('=');
      hash[key] = value;
      return hash;
    }, {});

export const parseLangFromUrl = (): void => {
  const urlParams = new URLSearchParams(window.location.search);
  const langParam = urlParams.get('lang');

  if (langParam) {
    saveLocale(langParam);
  }
};

export const getLocale = (): string => {
  parseLangFromUrl();
  return loadLocale();
};

export const isNullOrUndefined = (value: any): boolean => value === null || value === undefined;

export const capitalize = (str: string): string | null => {
  return typeof str === 'string' ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() : null;
};

export const isProduction = (): boolean => {
  if (
    window.location.host === URLS.DEV.EN ||
    window.location.host === URLS.DEV.FR ||
    window.location.host === URLS.TI.EN ||
    window.location.host === URLS.TI.FR ||
    window.location.host === URLS.TEST.EN ||
    window.location.host === URLS.TEST.FR ||
    process.env.NODE_ENV === ENV.DEV ||
    process.env.NODE_ENV === ENV.TEST
  ) {
    return false;
  }
  return true;
};

export const isCountryCA = (country?: string) => country === COUNTRIES.CA;
export const isCountryUS = (country?: string) => country === COUNTRIES.US;
export const isCountryCAorUS = (country?: string) => isCountryCA(country) || isCountryUS(country);

export const parseJwt = (token: string): { sub: string } => {
  const base64Url: string = token.split('.')[1];
  const base64: string = base64Url.replace(/-/g, '+').replace(/_/g, '/');

  const jsonPayload: string = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c: string): string => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

export const getBncId = (): string | null => {
  const tokenValue: string | null = loadItem('token');
  let bncId: string | null = null;

  if (tokenValue) {
    bncId = parseJwt(tokenValue).sub;
  }

  return bncId;
};
