import React, { Fragment, PureComponent } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import { getTaxSlice } from '../selectors';
import { getTextFactory } from 'utils/TextUtils';
import { InjectedIntl, injectIntl } from 'react-intl';
import SummarySection from './SummarySection';
import { BFF_STEPS_IDS } from 'utils/stepsConstants';
import SummaryField from './SummaryField';
import { Residency, TaxSlice } from '../types';
import SummaryFieldLabel from './SummaryFieldLabel';

export type TaxSectionPropsFromState = {
  taxSlice: TaxSlice;
};

export type TaxSectionProps = {
  goToStep: (stepId: string) => string;
  intl: InjectedIntl;
  isJointStep: boolean;
};

export class TaxSection extends PureComponent<TaxSectionProps & TaxSectionPropsFromState> {
  getTaxesText = getTextFactory(this.props.intl, 'tax.summary');
  getSummaryText = getTextFactory(this.props.intl, 'summary.tax');

  renderResidency = (residency: Residency, dataTest: string) => {
    const { locale } = this.props.intl;

    if (!residency) {
      return null;
    }

    return (
      <Row className="tax_residency" data-test={dataTest} key={dataTest}>
        <Col xs={12} md={4} xl={4}>
          <SummaryField dataTest="tax_country" label={this.getTaxesText('country')}>
            {residency.country[locale]}
          </SummaryField>
        </Col>
        {residency.province && (
          <Col xs={12} md={4} xl={4}>
            <SummaryField dataTest="tax_province" label={this.getTaxesText('province')}>
              {residency.province[locale]}
            </SummaryField>
          </Col>
        )}
        {!!residency.noRevealReason && (
          <Col xs={12} md={8} xl={8} className="tax_noreason">
            <SummaryField dataTest="tax_noRevealReason" label={this.getTaxesText('noRevealReason')}>
              {residency.noRevealReason[locale]}
            </SummaryField>
          </Col>
        )}
        {!!residency.tin && (
          <Col xs={12} md={4} xl={4}>
            <SummaryField dataTest="tax_tin" label={this.getTaxesText('tin')}>
              {residency.tin}
            </SummaryField>
          </Col>
        )}
        {!!residency.ssn && (
          <Col xs={12} md={4} xl={4}>
            <SummaryField dataTest="tax_ssn" label={this.getTaxesText('ssn')}>
              {residency.ssn}
            </SummaryField>
          </Col>
        )}
        {!!residency.itin && (
          <Col xs={12} md={4} xl={4}>
            <SummaryField dataTest="tax_itin" label={this.getTaxesText('itin')}>
              {residency.itin}
            </SummaryField>
          </Col>
        )}
      </Row>
    );
  };

  renderContent = () => {
    const { taxSlice } = this.props;

    if (!taxSlice) {
      return null;
    }

    const {
      socialInsuranceNumber,
      isMainResidencyCA,
      mainResidency,
      isOtherResidency,
      secondaryResidency,
      canChildrenBenInd,
    } = taxSlice;
    const { locale } = this.props.intl;

    const isMainResidencyCAAnswer = this.getTaxesText(isMainResidencyCA ? 'answerYes' : 'answerNo');
    const isCanadaChildBeneficiaryAnswer = this.getTaxesText(canChildrenBenInd ? 'answerYes' : 'answerNo');

    return (
      <div className="tax-recap-content">
        <Row>
          <Col xs={12} md={12} xl={12}>
            <SummaryField dataTest="tax_socialInsuranceNumber" label={this.getTaxesText('socialInsuranceNumber')}>
              {socialInsuranceNumber}
            </SummaryField>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12} xl={12}>
            <SummaryField dataTest="tax_isMainResidencyCA" label={this.getTaxesText('isMainResidencyCA')}>
              {isMainResidencyCAAnswer}
            </SummaryField>
          </Col>
        </Row>
        {isMainResidencyCA && mainResidency && mainResidency.province && (
          <Row>
            <Col xs={12} md={12} xl={12}>
              <SummaryField dataTest="tax_province" label={this.getTaxesText('province')}>
                {mainResidency.province[locale]}
              </SummaryField>
            </Col>
          </Row>
        )}
        {!isMainResidencyCA && (
          <Fragment>
            <Row>
              <Col xs={12} md={12} xl={12}>
                <SummaryFieldLabel>{this.getTaxesText('mainCountryQuestion')}</SummaryFieldLabel>
              </Col>
            </Row>
            {this.renderResidency(mainResidency, 'tax_main_residency')}
          </Fragment>
        )}
        <Row>
          <Col xs={12} md={12} xl={12}>
            <SummaryField dataTest="tax_isOtherResidency" label={this.getTaxesText('isOtherResidency')}>
              {isOtherResidency && isOtherResidency[locale]}
            </SummaryField>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12} xl={12}>
            <SummaryField dataTest="tax_canadianChildrenBenefit" label={this.getTaxesText('canadianChildrenBenefit')}>
              {isCanadaChildBeneficiaryAnswer}
            </SummaryField>
          </Col>
        </Row>
        {!!secondaryResidency && (
          <Fragment>
            <Row>
              <Col xs={12} md={12} xl={12}>
                <SummaryFieldLabel>{this.getTaxesText('secondaryCountry')}</SummaryFieldLabel>
              </Col>
            </Row>
            {secondaryResidency.map((residency, index) =>
              this.renderResidency(residency, `tax_secondary_residency_${index + 1}`),
            )}
          </Fragment>
        )}
      </div>
    );
  };

  render() {
    const { goToStep, intl, taxSlice, isJointStep } = this.props;

    return (
      <SummarySection
        intl={intl}
        name="tax"
        title={this.getSummaryText('pageTitle')}
        stepId={!isJointStep ? BFF_STEPS_IDS.TAXES : BFF_STEPS_IDS.TAXESJOINT}
        goToStep={goToStep}
        renderSectionContent={this.renderContent}
        editable={taxSlice && taxSlice.editable}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    taxSlice: getTaxSlice(state),
  };
};

export default connect<TaxSectionPropsFromState, {}, TaxSection>(mapStateToProps)(injectIntl(TaxSection));
