import { Heading } from '@nbc-design/heading';
import { Radio } from '@nbc-design/radio';
import { Text } from '@nbc-design/text';
import BaoTooltip from 'components/BaoTooltip/BaoTooltip';
import ErrorMessageDisplay from 'components/errorManager/ErrorMessageDisplay';
import { ProductFormInput, ProductFormSelect } from 'components/productFormFields';
import ProductFormDateFields from 'components/productFormFields/ProductFormDateFields';
import ProductFormOccupationSelect from 'components/productFormFields/ProductFormOccupationSelect';
import { EMPLOYMENT_V2_VALUES } from 'containers/EmploymentV2/constants';
import { EmployerPhoneNumber, Occupation } from 'containers/EmploymentV2/types';
import { getState } from 'globalRedux/store';
import { get, set } from 'lodash';
import React, { FunctionComponent } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { InjectedIntl } from 'react-intl';
import { initialize } from 'redux-form';
import { GenericProps, ProductForm } from 'types/interfaces';
import { RootState } from 'types/reducers';
import { getTextFactory } from 'utils/TextUtils';
import { BAO_FORMS, COUNTRIES } from 'utils/constants';
import { createErrorFormatter, getRefData } from 'utils/productFormUtils';
import EmployerAddress from '../EmployerAddress/EmployerAddress';
import './styles.scss';
import { capitalizeFirstLetter } from 'utils/stringUtils';
import EmployerPhone from '../EmployerPhone/EmployerPhone';

enum SOURCES {
  MANUAL = 'MANUAL',
  MELISSA = 'MELISSA',
}

export type ActiveOccupationsProps = GenericProps & {
  intl: InjectedIntl;
  productForm?: ProductForm;
  occupationType?: string | null;
  startOccupationDate?: string | null;
  employmentType?: string | null;
  employmentCode?: string | null;
  employerName?: string | null;
  employerPhoneNumber?: EmployerPhoneNumber;
  activeOccupations?: Occupation[];
  emplUID?: string | null;
  hasProInvolvement?: boolean;
  proInvolvementCompanyName?: string;
  proInvolvementFirstName?: string;
  proInvolvementLastName?: string;
  proInvolvementRelation?: string;
  proInvolvementRelationOther?: string;
  hasAdminInvolvement?: boolean;
  hasTenPercentInvolvement?: boolean;
  hasTwentyPercentInvolvement?: boolean;
  adminInvolvementList?: object[];
  tenPercentInvolvementList?: object[];
  twentyPercentInvolvementList?: object[];
};

const ActiveOccupations: FunctionComponent<ActiveOccupationsProps> = (props: ActiveOccupationsProps) => {
  const {
    intl,
    occupationType = null,
    change,
    productForm,
    activeOccupations = [],
    emplUID,
    submitFailed,
    error,
    dispatch,
    refData,
    schema,
    hasProInvolvement,
    proInvolvementCompanyName,
    proInvolvementFirstName,
    proInvolvementLastName,
    proInvolvementRelation,
    proInvolvementRelationOther,
    hasAdminInvolvement,
    hasTenPercentInvolvement,
    hasTwentyPercentInvolvement,
    adminInvolvementList,
    tenPercentInvolvementList,
    twentyPercentInvolvementList,
  }: ActiveOccupationsProps = props;

  const state: RootState | null = getState();
  const productFormActiveOccupations = get(
    state,
    'productFormReducer.baoForms.productForm.values.activeOccupations',
    {},
  );

  const formChange = (occupation: Occupation) => {
    if (occupation.occupationType !== EMPLOYMENT_V2_VALUES.EMPLOYED) {
      change('startOccupationDate', occupation.startOccupationDate);
    } else {
      change('startOccupationDate', occupation.startOccupationDate);
      change('employmentCode', occupation.employmentCode === undefined ? null : occupation?.employmentCode);
      change('employmentType', occupation.employmentType === undefined ? null : occupation?.employmentType);
      change('employerName', occupation.employerName === undefined ? null : occupation?.employerName);
      change('employerAddress', occupation.employerAddress === undefined ? null : occupation?.employerAddress);

      change(
        'employerPhoneNumber.country',
        occupation.employerPhoneNumber?.country === undefined ? COUNTRIES.CA : occupation?.employerPhoneNumber.country,
      );
      change(
        'employerPhoneNumber.number',
        occupation.employerPhoneNumber?.number === undefined ? '' : occupation?.employerPhoneNumber?.number,
      );
      change(
        'employerPhoneNumber.extensionNumber',
        occupation.employerPhoneNumber?.extensionNumber === undefined
          ? ''
          : occupation?.employerPhoneNumber.extensionNumber,
      );
    }
  };

  const formClear = () => {
    dispatch(
      initialize(BAO_FORMS, {
        hasProInvolvement,
        proInvolvementCompanyName,
        proInvolvementFirstName,
        proInvolvementLastName,
        proInvolvementRelation,
        proInvolvementRelationOther,
        hasAdminInvolvement,
        hasTenPercentInvolvement,
        hasTwentyPercentInvolvement,
        adminInvolvementList,
        tenPercentInvolvementList,
        twentyPercentInvolvementList,
        activeOccupations: productFormActiveOccupations,
        startOccupationDate: null,
        employerAddress: null,
        employmentType: null,
        employmentCode: null,
        employerName: null,
        emplUID: emplUID === undefined ? '' : emplUID,
        employerPhoneNumber: {
          country: COUNTRIES.CA,
          number: '',
          extensionNumber: '',
        },
        occupationType,
      }),
    );
  };

  const phoneAreaCodesRefData = getRefData({ productForm, schema, refData }, 'phoneAreaCodes');

  const isShowEmployedForm = occupationType === EMPLOYMENT_V2_VALUES.EMPLOYED;
  const isEmptyOccupations = activeOccupations?.filter((data) => data.occupationType === occupationType)?.length === 0;
  const filterActiveOccupations = activeOccupations?.filter((data) => data.occupationType === occupationType);
  const errorMessage = error ? createErrorFormatter(intl, 'error')(error) : undefined;

  React.useEffect(() => {
    const occupation = activeOccupations?.find((data) => data.emplUID === emplUID);

    if (occupation === undefined) {
      formClear();
    } else {
      formChange(occupation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emplUID]);

  React.useEffect(() => {
    const mainOccupation = activeOccupations?.find((data) => data.occupationType === occupationType && data.primary);

    if (mainOccupation === undefined) {
      if (filterActiveOccupations.length === 0) {
        change('emplUID', '');
      } else {
        change('emplUID', null);
      }
    } else {
      change('emplUID', mainOccupation.emplUID);
      formChange(mainOccupation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [occupationType]);

  const getText = getTextFactory(intl, 'employmentV2');
  const getGlobalText = getTextFactory(intl, 'global');

  const formatErrorMessage = (prefix?: string) => {
    return createErrorFormatter(intl, prefix);
  };

  const buildOccupationLabel = (occupation: Occupation) => {
    if (EMPLOYMENT_V2_VALUES.EMPLOYED === occupationType) {
      return `${capitalizeFirstLetter(occupation?.employmentLabel?.[intl.locale])} ${getText('at')} 
      ${occupation.employerName}`;
    } else {
      let formattedDate = new Date(occupation.startOccupationDate).toLocaleDateString(intl.locale, {
        month: 'long',
        year: 'numeric',
      });
      return `${getText('since')} ${formattedDate}`;
    }
  };

  const renderOccupation = () => {
    return (
      <div className={!isEmptyOccupations ? 'occupation-area' : ''}>
        {isShowEmployedForm && emplUID === '' && (
          <Row>
            <Col xs={12} md={6} lg={6} data-test="field_employment_type">
              <ProductFormSelect
                name="employmentType"
                label={getText('employmentType')}
                formatErrorMessage={formatErrorMessage()}
                placeholder={getText('selectOccupation')}
                sort={true}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={12} md={7} lg={7} data-test="field_occupation_date">
            <ProductFormDateFields
              name="startOccupationDate"
              change={change}
              label={getText('startDate')}
              dateFieldsOptions={{
                display: {
                  day: false,
                  month: true,
                  year: true,
                },
              }}
              formatErrorMessage={emplUID === '' ? formatErrorMessage() : formatErrorMessage('dateMainOccupation')}
              isUpdate={
                emplUID === null ||
                emplUID === '' ||
                !activeOccupations?.find((data) => data.occupationType === occupationType)
              }
            />
          </Col>
        </Row>
        {isShowEmployedForm && (
          <>
            {emplUID === '' && (
              <>
                <Row>
                  <Col xs={12} md={6} lg={6} data-test="field_occupation_title">
                    <ProductFormOccupationSelect
                      data={props['employmentCode']}
                      name={'employmentCode'}
                      label={getText('occupationTitle')}
                      formatErrorMessage={formatErrorMessage()}
                      change={change}
                    />
                  </Col>
                </Row>

                <Heading type="h6" size={6} data-test="label_employer_info" className="mc-mt-normal mc-mb-medium">
                  {getText('employerInfo')}
                </Heading>

                <Row>
                  <Col xs={12} md={6} lg={6} data-test="field_employer_name">
                    <ProductFormInput
                      name="employerName"
                      label={getText('employerName')}
                      formatErrorMessage={formatErrorMessage()}
                    />
                  </Col>
                </Row>
              </>
            )}

            <EmployerAddress
              productForm={productForm}
              change={change}
              getText={getText}
              getGlobalText={getGlobalText}
              errorLabel={emplUID === '' ? 'address' : 'addressMainOccupation'}
              placeHolder={'addressOccupationSearch'}
            />

            <EmployerPhone name="employerPhoneNumber" refData={phoneAreaCodesRefData} change={change} />
          </>
        )}
      </div>
    );
  };

  const switchOccupation = (occupation: Occupation) => {
    if (occupation.occupationType === EMPLOYMENT_V2_VALUES.EMPLOYED) {
      change('employerAddress', {
        ...occupation?.employerAddress,
        isManualMode: false,
        addrSource: SOURCES.MELISSA,
      });

      change('startOccupationDate', occupation.startOccupationDate);

      // FIXME: Find a proper way to clear complement and province fields
      set(
        state,
        'productFormReducer.baoForms.productForm.values.employerAddress.complement',
        occupation?.employerAddress?.complement,
      );
      set(
        state,
        'productFormReducer.baoForms.productForm.values.employerAddress.province',
        occupation?.employerAddress?.province,
      );
    }

    change('emplUID', occupation.emplUID);
  };

  const renderNewOccupation = () => {
    let selectNewOccupation = emplUID === '';

    return (
      <div className="active-occupations-last mc-pr-medium">
        <Radio
          id={`radio-new-occupation`}
          name={`radio-new-occupation`}
          label={getText('createNewOccupation')}
          onChange={() => change('emplUID', '')}
          checked={selectNewOccupation}
        />
        {selectNewOccupation && <div className={`form-new-occupation`}>{renderOccupation()}</div>}
      </div>
    );
  };

  const renderOccupationRadio = (occupation: Occupation, index: number) => {
    let selectActiveOccupation = emplUID === occupation.emplUID;

    return (
      <div className="active-occupations mc-pr-medium">
        <Radio
          id={`radio-occupation-${index}`}
          name={`radio-${index}`}
          label={buildOccupationLabel(occupation)}
          value={occupation.emplUID}
          onChange={() => switchOccupation(occupation)}
          checked={selectActiveOccupation}
        />
        {selectActiveOccupation && occupationType === EMPLOYMENT_V2_VALUES.EMPLOYED && (
          <div className={`form-occupation-${index}`}>{renderOccupation()}</div>
        )}
      </div>
    );
  };

  return (
    <div className="occupation-section">
      {!isEmptyOccupations && (
        <>
          <Text className="mc-my-normal">{getText('occupationDescription')}</Text>

          <Row className="mc-my-normal">
            {!isEmptyOccupations && (
              <Col className="occupation-list" xs={12} md={12} lg={12}>
                {filterActiveOccupations?.map((activeOccupation, index) =>
                  renderOccupationRadio(activeOccupation, index),
                )}
                {renderNewOccupation()}
              </Col>
            )}
          </Row>
        </>
      )}

      {isEmptyOccupations && (
        <Row>
          <Col xs={12} md={12} lg={12}>
            {renderOccupation()}
          </Col>
        </Row>
      )}

      <ErrorMessageDisplay visible={submitFailed && !!error} message={errorMessage} />

      {!isEmptyOccupations && (
        <div className="occupation-section mc-mb-large">
          <Text className="occupation-footer-description">
            {getText('occupationQuestion')}
            <BaoTooltip
              name={'occupation-section-tooltip'}
              stepId={productForm.meta.stepId}
              content={getText('occupationQuestionTooltip')}
              position="top"
              className="occupation-section-tooltip"
            />
          </Text>
        </div>
      )}
    </div>
  );
};

export default ActiveOccupations;
