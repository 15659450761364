import React, { FC, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import get from 'lodash/get';
import { FormGroup } from '@nbc-design/form-group';
import { Button } from '@nbc-design/button';
import { PersonalInformationProps, PhoneNumber } from './types';
import {
  createAddressErrorFormatter,
  createErrorFormatter,
  getRefData,
  isFieldDisabled,
  isJointMenu,
} from 'utils/productFormUtils';
import Collection from 'components/Collection';
import {
  ProductFormDateFields,
  ProductFormInput,
  ProductFormPhoneInput,
  ProductFormRadioSelect,
  ProductFormSelect,
} from 'components/productFormFields';
import { Address } from 'components/productFormFields/ProductFormAddressSelect/types';
import Title from 'components/Title';
import PersonalInfo from './components/PersonalInfo';
import PersonnalInfoAddresses from './components/PersonnalInfoAddresses';
import ReadOnlyAddresses from './components/ReadOnlyAddresses';
import CONFIG from './config';
import { Text } from '@nbc-design/text';
import { formatHolderName, updateReduxForm } from './utils';
import './styles.scss';
import { Link } from '@nbc-design/link';
import { LANG } from 'utils/constants';
import { Alert } from '@nbc-design/alert';
import { getTextFactory } from 'utils/TextUtils';

const PersonalInformation: FC<PersonalInformationProps> = (props: PersonalInformationProps) => {
  const {
    intl,
    productForm,
    refData,
    schema,
    hasMailingAddress = false,
    phoneNumbers = [{} as PhoneNumber],
    citizenship = [''],
    mailingAddressUsage = null,
    change,
    meta,
    isMultiHolderMode,
    displayRelationshipInput = false,
    shouldUpdateRelationship = false,
    mainHolderName,
  } = props;

  const getGlobalText = getTextFactory(intl, 'global');
  const getText = getTextFactory(intl, 'personalInformation');
  const getAccessibilityText = getTextFactory(intl, 'accessibility');

  React.useEffect(() => {
    isJointMenu(meta) && isMultiHolderMode && updateReduxForm();
  }, [meta, isMultiHolderMode]);

  const displayTitle: boolean = get(productForm, 'values.displayTitle') || false;
  const connectedClient: boolean = get(productForm, 'values.filledByMcp');
  const currentAddress: Address = get(productForm, 'values.currentAddress');
  const futureAddresses: Address[] = get(productForm, 'values.futureAddresses') || [];
  const citizenshipRefData = getRefData({ productForm, schema, refData }, 'citizenship');
  const phoneTypeRefData = getRefData({ productForm, schema, refData }, 'telephoneType');

  const [editingAddress, setEditingAddress] = useState(currentAddress?.isManualMode || false);

  const formatErrorMessage = (prefix?: string, suffix?: string) =>
    prefix === 'address' ? createAddressErrorFormatter(intl) : createErrorFormatter(intl, prefix, suffix);

  const handleModifyAddress = (isEditingAddress: boolean) => {
    change('currentAddress', currentAddress);
    setEditingAddress(isEditingAddress);
  };

  const isJointStep = meta && meta.stepId === CONFIG.STEP_JOINT_ID;

  return (
    <div id="personal-information-form">
      {isJointMenu(meta) && (
        <Text size="large" className={isJointStep ? 'joint-spouse-title' : 'joint-applicant-title'}>
          {isJointStep ? getGlobalText('spouseMenuTitle') : getGlobalText('applicantMenuTitle')}
        </Text>
      )}

      <Title label={getText('pageTitle')} classNameTitle={shouldUpdateRelationship ? 'mc-pb-normal' : ''} />

      {displayRelationshipInput && shouldUpdateRelationship && (
        <>
          <Row>
            <Col xs={12} className="label mc-mb-large">
              <Alert
                description={`${getText('spouseInfoWarning')} ${formatHolderName(mainHolderName!)}`}
                type={'h3'}
                appearance="warning"
                ariaLabelIcon={getAccessibilityText('icon.warning')}
                isClosable={false}
                dataTest="alert_RelationshipStatus"
              />
            </Col>
          </Row>
        </>
      )}

      {connectedClient ? (
        <>
          {displayTitle && (
            <Row>
              <Col xs={12} md={6} data-test="field_title">
                <ProductFormRadioSelect
                  name="title"
                  label={getText('title')}
                  formatErrorMessage={formatErrorMessage()}
                  inline
                />
              </Col>
            </Row>
          )}

          <PersonalInfo productForm={productForm} intl={intl}>
            {currentAddress && !editingAddress ? (
              <>
                <ReadOnlyAddresses
                  addresses={[currentAddress]}
                  intl={intl}
                  onModifyAddress={() => handleModifyAddress(true)}
                  isCurrentAddress
                />
                {futureAddresses.length > 0 && <ReadOnlyAddresses addresses={futureAddresses} intl={intl} />}
              </>
            ) : (
              <>
                {currentAddress && (
                  <div className="actions-area">
                    <Button appearance="minimal" size="small" onClick={() => handleModifyAddress(false)}>
                      {getGlobalText('button.cancel')}
                    </Button>
                  </div>
                )}
                <PersonnalInfoAddresses
                  productForm={productForm}
                  mailingAddressUsage={mailingAddressUsage}
                  change={change}
                  getText={getText}
                  getGlobalText={getGlobalText}
                  formatErrorMessage={formatErrorMessage}
                  hasMailingAddress={false}
                  showSelectOtherAddress={false}
                />
              </>
            )}
          </PersonalInfo>
        </>
      ) : (
        <>
          {/* Title */}
          <Row>
            <Col xs={12} md={6} data-test="field_title">
              <ProductFormRadioSelect
                name="title"
                label={getText('title')}
                formatErrorMessage={formatErrorMessage()}
                inline
              />
            </Col>
          </Row>

          {/* First Name && Middle Name */}
          <Row>
            <Col xs={12} md={6} data-test="field_firstName">
              <ProductFormInput
                name="firstName"
                label={getGlobalText('firstName')}
                formatErrorMessage={formatErrorMessage()}
                disabled={isFieldDisabled(get(productForm, 'schema'), 'firstName')}
              />
            </Col>
            <Col xs={12} md={6} data-test="field_middleName">
              <ProductFormInput
                name="middleName"
                label={getGlobalText('middleName')}
                optionalText={getGlobalText('optional')}
                formatErrorMessage={formatErrorMessage()}
                disabled={isFieldDisabled(get(productForm, 'schema'), 'middleName')}
              />
            </Col>
          </Row>

          {/* Last Name */}
          <Row>
            <Col xs={12} md={8} data-test="field_lastName">
              <ProductFormInput
                name="lastName"
                label={getGlobalText('lastName')}
                formatErrorMessage={formatErrorMessage()}
                disabled={isFieldDisabled(get(productForm, 'schema'), 'lastName')}
              />
            </Col>
          </Row>

          {/* Alias */}
          <Row>
            <Col xs={12} md={8} data-test="field_alias">
              <ProductFormInput
                name="alias"
                label={getText('alias')}
                formatErrorMessage={formatErrorMessage()}
                disabled={isFieldDisabled(get(productForm, 'schema'), 'alias')}
              />
            </Col>
          </Row>

          {/* Birth Date */}
          <Row>
            <Col xs={12} md={8} data-test="field_birthDate">
              <ProductFormDateFields
                name="birthDate"
                change={change}
                label={getGlobalText('birthDate')}
                formatErrorMessage={formatErrorMessage()}
                disabled={isFieldDisabled(get(productForm, 'schema'), 'birthDate')}
              />
            </Col>
          </Row>
        </>
      )}

      <PersonnalInfoAddresses
        productForm={productForm}
        getText={getText}
        getGlobalText={getGlobalText}
        hasMailingAddress={hasMailingAddress}
        mailingAddressUsage={mailingAddressUsage}
        formatErrorMessage={formatErrorMessage}
        change={change}
        showMainAddressValue={!connectedClient}
      />

      {/* Email */}
      <Row>
        <Col xs={12} md={8} data-test="field_email">
          <ProductFormInput
            name="email"
            className="email"
            label={getGlobalText('email')}
            helpText={getText('emailConfirmationMessage')}
            formatErrorMessage={formatErrorMessage()}
          />
        </Col>
      </Row>

      {/* Phone Number(s) */}
      <div className="collection-wrapper" data-test="fields_phoneNumber">
        <FormGroup
          className="help-text"
          label={{
            text: getText('phoneNumbers'),
            htmlFor: 'phoneNumbers[0]',
          }}
          description={{
            text: getText('esignInfo'),
            id: 'phoneNumbers-description',
          }}
        >
          <div />
        </FormGroup>
        <Collection
          name="phoneNumbers"
          typeComponent={ProductFormPhoneInput}
          data={phoneNumbers}
          refData={phoneTypeRefData}
          maxItems={3}
          addLabel={getText('addPhoneNumber')}
          change={change}
          formatErrorMessage={formatErrorMessage('personalInformation', 'phoneNumber')}
        />
      </div>

      {/* Marital Status */}
      <Row>
        <Col xs={12} md={6} data-test="field_maritalStatus">
          <ProductFormSelect
            name="maritalStatus"
            label={getText('maritalStatus')}
            formatErrorMessage={formatErrorMessage()}
            placeholder={getGlobalText('select')}
          />
        </Col>
      </Row>

      {displayRelationshipInput && (
        <>
          <Row>
            <Col xs={12} md={6} data-test="field_relationshipStatus">
              <ProductFormSelect
                name="oppositePartyRole"
                label={`${getText('relationshipStatusLabel')} ${formatHolderName(mainHolderName!)}${getText(
                  'relationshipStatusLabel2',
                )}`}
                formatErrorMessage={formatErrorMessage()}
                placeholder={getGlobalText('select')}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} data-test="field_RESPAdmissibility" className="label">
              <Alert
                description={getText('RESPAdmissibility')}
                type={'p'}
                appearance="information"
                ariaLabelIcon={getAccessibilityText('icon.information')}
                className={'mc-mb-medium'}
                link={
                  <Link
                    href={
                      intl.locale.includes(LANG.EN)
                        ? 'https://www.canada.ca/en/revenue-agency/services/tax/individuals/topics/registered-education-savings-plans-resps/who-a-subscriber.html'
                        : 'https://www.canada.ca/fr/agence-revenu/services/impot/particuliers/sujets/regime-enregistre-epargne-etudes-reee/peut-devenir-souscripteur.html'
                    }
                    target="_blank"
                    underlined={true}
                  >
                    {getText('RESPAdmissibilityLink')}
                  </Link>
                }
                isClosable={false}
              />
            </Col>
          </Row>
        </>
      )}

      {/* Citizenship(s) */}
      <div className="collection-wrapper" data-test="fields_citizenship">
        <FormGroup
          className="help-text"
          label={{
            text: getText('citizenship'),
            htmlFor: 'citizenship[0]',
          }}
          description={{
            text: getText('citizenshipLaws'),
            id: 'citizenship-description',
          }}
        >
          <div />
        </FormGroup>
        <Collection
          name="citizenship"
          typeComponent={ProductFormSelect}
          data={citizenship}
          refData={citizenshipRefData}
          maxItems={2}
          placeholder={getGlobalText('select')}
          addLabel={getText('addCitizenship')}
          change={change}
          formatErrorMessage={formatErrorMessage()}
          filter={({ value }: { value: string }) => citizenship && !citizenship.includes(value)}
          sort
        />
      </div>
    </div>
  );
};

export default PersonalInformation;
