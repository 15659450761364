import { InjectedIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';

import { ISuggestion } from '@nbc-design/autocomplete/dist/interface';

import { ProductForm, Schema } from 'types/interfaces';
import { getRefData } from 'utils/productFormUtils';
import { Address } from './types';

export const formatAddress = (address: Address, omitComplement: boolean, complementSuffix?: string): string => {
  if (!address || isEmpty(omit(address, 'complement', 'ownership', 'startDate'))) {
    return '';
  }

  const { streetName, streetNumber, city, postalCode, country, province, complement } = address;

  let streetInfo = `${streetNumber} ${streetName}`;
  if (!omitComplement && !complementSuffix && complement) {
    streetInfo = `${complement}-${streetNumber} ${streetName}`;
  }

  const apartment = !omitComplement && complement && complementSuffix ? `${complementSuffix} ${complement}` : null;
  return [streetInfo, city, `${removeCountryCodeFromProvince(province)} ${postalCode}`, country, apartment]
    .filter((x) => !!x)
    .join(', ');
};

export const removeCountryCodeFromProvince = (province: string): string => {
  if (!province || province.length <= 0) {
    return province;
  }

  const seperatorIndex = province.indexOf('-');

  if (seperatorIndex < 0) {
    return province;
  }

  return province.substr(seperatorIndex + 1);
};

export const addCountryCodeToProvince = (province: string, countryCode: string): string => {
  if (!province || province.length <= 0 || !countryCode || countryCode.length <= 0) {
    return province;
  }

  const seperatorIndex = province.indexOf('-');

  if (seperatorIndex >= 0) {
    return province;
  }

  return `${countryCode}-${province}`;
};

export const getCountryFromRefData = (
  country: string,
  productForm: ProductForm,
  schema: Schema,
  intl: InjectedIntl,
) => {
  const countryRefData = getRefData({ productForm, schema }, 'country');
  const countryLabel = country ? countryRefData.find(({ valueDomainCode }) => valueDomainCode === country) : null;

  return countryLabel ? countryLabel[intl.locale].label : null;
};

export const formatAddressOption = (productForm: ProductForm, schema: Schema, intl: InjectedIntl) => {
  return (address: Address, index: number): ISuggestion => {
    const countryLabel = getCountryFromRefData(address.country, productForm, schema, intl);

    const addressWithCountry = {
      ...address,
      ...(countryLabel ? { country: countryLabel } : {}),
    };

    return {
      label: formatAddress(addressWithCountry, false) || '',
      value: `${index}`,
      data: {
        ...address,
        province: addCountryCodeToProvince(address.province, address.country),
        full: formatAddress(addressWithCountry, true) || '',
      },
    };
  };
};

export const getSelectOptions = (addresses: Address[], productForm: ProductForm, schema: Schema, intl: InjectedIntl) =>
  addresses.map(formatAddressOption(productForm, schema, intl));
