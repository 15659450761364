import React, { FC } from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import includes from 'lodash/includes';
import get from 'lodash/get';

import { AllRefData, RefData, ProductForm, ProductFormFieldChangeHandler } from 'types/interfaces';
import { getTextFactoryReturnType } from 'utils/TextUtils';
import AddressInput from 'components/AddressInput';

export type EmployerAddressProps = {
  productForm: ProductForm;
  getText: getTextFactoryReturnType;
  getGlobalText: getTextFactoryReturnType;
  change: ProductFormFieldChangeHandler;
  intl: InjectedIntl;
  errorLabel?: string;
  placeHolder?: string;
};

const EmployerAddress: FC<EmployerAddressProps> = ({
  productForm,
  intl,
  change,
  getGlobalText,
  getText,
  errorLabel,
  placeHolder,
}) => {
  const provincesToRemove = ['CA-YT', 'CA-NU', 'CA-NT'];
  const provinceCA: RefData[] = get(productForm, 'schema.definitions.AddressUI.properties.provinceCA.refData', []);
  const provinceUS: RefData[] = get(productForm, 'schema.definitions.AddressUI.properties.provinceUS.refData', []);
  const currentAddressRefData: AllRefData = {
    provinceCA: provinceCA.filter((province) => !includes(provincesToRemove, province.valueDomainCode)),
    provinceUS,
  };
  return (
    <>
      <div className="current-address" data-test="field_currentAddress">
        <AddressInput
          name="employerAddress"
          textPrefix="employment"
          helpText="indicated"
          allRefData={currentAddressRefData}
          change={change}
          isShowApt={false}
          errorLabel={errorLabel}
          label={getGlobalText('occupationEmployerAddress')}
          placeHolder={placeHolder}
        />
      </div>
    </>
  );
};

export default injectIntl(EmployerAddress);
