import { FunctionComponent } from 'react';
import { InjectedIntl } from 'react-intl';
import { GenericProps } from 'types/interfaces';
import { EMPLOYMENT_VALUES } from './constants';
import { getTextFactory } from 'utils/TextUtils';
import { createAddressErrorFormatter, createErrorFormatter, createStockSymbolFormatter } from 'utils/productFormUtils';
import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import Collection from 'components/Collection';
import { FormGroup } from '@nbc-design/form-group';
import {
  ProductFormInput,
  ProductFormRadioBoolean,
  ProductFormSelect,
  ProductFormStockSymbolSelect,
} from 'components/productFormFields';
import './styles.scss';
import Heading from 'components/Heading';

type FinancialInvolvementProps = GenericProps & {
  intl: InjectedIntl;
  proInvolvementRelation?: string;
  proInvolvementCompanyName?: string;
  proInvolvementFirstName?: string;
  proInvolvementLastName?: string;
  proInvolvementRelationOther?: string;
  hasBrokerageInvolvement?: boolean;
  hasProInvolvement?: boolean;
  hasAdminInvolvement?: boolean;
  hasTenPercentInvolvement?: boolean;
  hasTwentyPercentInvolvement?: boolean;
  adminInvolvementList?: object[];
  tenPercentInvolvementList?: object[];
  twentyPercentInvolvementList?: object[];
};

const FinancialInvolvement: FunctionComponent<FinancialInvolvementProps> = (props: FinancialInvolvementProps) => {
  const {
    intl,
    proInvolvementRelation = EMPLOYMENT_VALUES.TRADING_RELATION_STATUS_I_AM,
    hasProInvolvement = EMPLOYMENT_VALUES.TRADING_INVOLVEMENT_NO,
    change,
  }: FinancialInvolvementProps = props;

  const isProInvolvement = hasProInvolvement && hasProInvolvement === EMPLOYMENT_VALUES.TRADING_INVOLVEMENT_YES;

  const isOtherProInvolvementRelation =
    proInvolvementRelation && proInvolvementRelation === EMPLOYMENT_VALUES.TRADING_RELATION_STATUS_OTHER;
  const isSelfProInvolvementRelation =
    proInvolvementRelation && proInvolvementRelation === EMPLOYMENT_VALUES.TRADING_RELATION_STATUS_I_AM;

  const getText = getTextFactory(intl, 'employment');
  const getGlobalText = getTextFactory(intl, 'global');

  const formatErrorMessage = (prefix?: string) => {
    if (prefix === 'address') {
      return createAddressErrorFormatter(intl);
    } else if (prefix === 'symbol') {
      return createStockSymbolFormatter(intl);
    } else {
      return createErrorFormatter(intl, prefix);
    }
  };

  const handleInvolvementChange = (listFieldName: string) => (isInvolved: boolean) => {
    change(listFieldName, isInvolved ? [null] : []);
  };

  return (
    <>
      <Heading type={'h2'} size={4}>
        {getText('hasBrokerageInvolvement')}
      </Heading>

      <div>
        <Row>
          <Col xs={12} md={12} lg={12} data-test="field_hasProInvolvement">
            <ProductFormRadioBoolean
              name="hasProInvolvement"
              yesLabel={getGlobalText('button.yes')}
              noLabel={getGlobalText('button.no')}
              helpText={getText('hasProInvolvementHelpText')}
              inline
              label={getText('hasProInvolvement')}
              formatErrorMessage={formatErrorMessage()}
              className="employment__involvement-question"
            />
          </Col>
        </Row>

        {isProInvolvement && (
          <div>
            <Row className="pro-involvement-info">
              <Col xs={12} md={12} lg={12} data-test="hasProInvolvementInfo">
                <p>{getText('hasProInvolvementInfo')}</p>
              </Col>
            </Row>
            <p />
            <Row>
              <Col xs={12} md={6} lg={6} data-test="field_proInvolvementCompanyName">
                <ProductFormInput
                  name="proInvolvementCompanyName"
                  label={getText('proInvolvementCompanyName')}
                  formatErrorMessage={formatErrorMessage()}
                />
              </Col>
              <Col xs={12} md={6} lg={6} data-test="field_proInvolvementRelation">
                <ProductFormSelect
                  name="proInvolvementRelation"
                  placeholder={getGlobalText('select')}
                  label={getText('proInvolvementRelation')}
                  formatErrorMessage={formatErrorMessage()}
                />
              </Col>
            </Row>

            {isOtherProInvolvementRelation && (
              <Row>
                <Col xs={12} md={12} lg={12} data-test="field_proInvolvementRelationOther">
                  <ProductFormInput
                    name="proInvolvementRelationOther"
                    label={getText('proInvolvementRelationOther')}
                    formatErrorMessage={formatErrorMessage()}
                  />
                </Col>
              </Row>
            )}
            {!isSelfProInvolvementRelation && (
              <Row>
                <Col xs={12} md={6} lg={6} data-test="field_proInvolvementFirstName">
                  <ProductFormInput
                    name="proInvolvementFirstName"
                    label={getGlobalText('firstName')}
                    formatErrorMessage={formatErrorMessage()}
                  />
                </Col>
                <Col xs={12} md={6} lg={6} data-test="field_proInvolvementLastName">
                  <ProductFormInput
                    name="proInvolvementLastName"
                    label={getGlobalText('lastName')}
                    formatErrorMessage={formatErrorMessage()}
                  />
                </Col>
              </Row>
            )}
          </div>
        )}

        <Heading type={'h2'} size={4}>
          {getText('insider')}
        </Heading>

        {[
          ['hasAdminInvolvement', 'adminInvolvementList'],
          ['hasTenPercentInvolvement', 'tenPercentInvolvementList'],
          ['hasTwentyPercentInvolvement', 'twentyPercentInvolvementList'],
        ].map(([fieldName, listName]) => (
          <React.Fragment key={`${fieldName}`}>
            <Row>
              <Col xs={12} md={12} lg={12} data-test={`field_${fieldName}`}>
                <ProductFormRadioBoolean
                  name={fieldName}
                  yesLabel={getGlobalText('button.yes')}
                  noLabel={getGlobalText('button.no')}
                  inline
                  label={getText(fieldName)}
                  formatErrorMessage={formatErrorMessage()}
                  className="employment__involvement-question"
                  onChange={handleInvolvementChange(listName)}
                />
              </Col>
            </Row>

            {props[fieldName] === EMPLOYMENT_VALUES.TRADING_INVOLVEMENT_YES && (
              <Row className="collection-wrapper">
                <Col xs={12} md={12} lg={12} data-test={`field_${listName}`}>
                  <FormGroup
                    label={{
                      text: getText(listName),
                      htmlFor: `symbol-autocomplete-${listName}[0]`,
                    }}
                    className="help-text"
                  >
                    {null}
                  </FormGroup>
                  <Collection
                    data={props[listName]}
                    name={listName}
                    label={getText(listName)}
                    placeholder={getGlobalText('search')}
                    formatErrorMessage={formatErrorMessage()}
                    typeComponent={ProductFormStockSymbolSelect}
                    change={change}
                    defaultValue={null}
                    addLabel={getText('addSymbol')}
                  />
                </Col>
              </Row>
            )}
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export default FinancialInvolvement;
