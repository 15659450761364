import React, { FC } from 'react';
import { InjectedIntl } from 'react-intl';
import { Row, Col } from 'react-flexbox-grid';
import { ProductFormField } from 'bnc-react-forms';
import { Text } from '@nbc-design/text';

import { AddressDetailedData, AllRefData, RefData, ProductFormFieldChangeHandler } from 'types/interfaces';
import { ErrorType, FormComponentProps } from 'components/productFormFields/types';
import { ProductFormDateFields, ProductFormInput, ProductFormSelect } from 'components/productFormFields';
import { isCountryCAorUS } from 'utils';
import { getTextFactory } from 'utils/TextUtils';
import CancelButton from './CancelButton';
import './style.scss';

export type ManualAddressFormProps = {
  name: string;
  intl: InjectedIntl;
  allRefData: AllRefData;
  className?: string;
  helpText?: string;
  isReadOnly?: boolean;
  formatErrorMessage: (error: ErrorType, props: FormComponentProps) => string;
  change: ProductFormFieldChangeHandler;
};

const ManualAddressForm: FC<ManualAddressFormProps> = (props) => {
  const { name, formatErrorMessage, intl, className, allRefData, change, helpText, isReadOnly } = props;

  const getGlobalText = getTextFactory(intl, 'global');
  const getText = getTextFactory(intl, 'address');

  const address: AddressDetailedData = props[name];
  if (!address || !address.isManualMode) {
    return null;
  }

  const hasProvince = isReadOnly ? true : isCountryCAorUS(address.country);
  const province: RefData[] = isReadOnly ? allRefData.provinceCA : allRefData[`province${address.country}`];
  const country: RefData[] = allRefData['country'];

  const handleOnCountryChange = () => {
    // Reset province field
    change(`${name}.province`, null);
  };

  return (
    <div className={className}>
      <Row>
        {/* Street Number */}
        <Col xs={12} lg={6} data-test="field_streetNumber">
          <ProductFormInput
            name={`${name}.streetNumber`}
            label={getText('streetNumber')}
            formatErrorMessage={formatErrorMessage}
            maxLength={32}
          />
        </Col>

        {/* Street Name */}
        <Col xs={12} lg={6} data-test="field_streetName">
          <ProductFormInput
            name={`${name}.streetName`}
            label={getText('streetName')}
            formatErrorMessage={formatErrorMessage}
            maxLength={32}
          />
        </Col>
      </Row>

      <Row>
        {/* Complement */}
        <Col xs={12} lg={6} data-test="field_complement">
          <ProductFormInput
            name={`${name}.complement`}
            label={getText('appartmentLabel')}
            optionalText={getGlobalText('optional')}
            formatErrorMessage={formatErrorMessage}
            maxLength={6}
          />
        </Col>

        {/* City */}
        <Col xs={12} lg={6} data-test="field_city">
          <ProductFormInput
            name={`${name}.city`}
            label={getText('city')}
            formatErrorMessage={formatErrorMessage}
            maxLength={32}
          />
        </Col>
      </Row>

      <Row>
        {/* Postal Code */}
        <Col xs={12} lg={6} data-test="field_postalCode">
          <ProductFormInput
            name={`${name}.postalCode`}
            label={getText('postalCode')}
            formatErrorMessage={formatErrorMessage}
            maxLength={10}
          />
        </Col>

        {/* Country */}
        <Col xs={12} lg={6} data-test="field_country">
          <ProductFormSelect
            isReadOnly={isReadOnly}
            name={`${name}.country`}
            refData={country}
            label={getText('country')}
            placeholder={getText('select.placeholder')}
            formatErrorMessage={formatErrorMessage}
            onChange={handleOnCountryChange}
            sort
          />
        </Col>
      </Row>

      {/* Province */}
      {hasProvince && (
        <Row>
          <Col xs={12} lg={6} data-test="field_province">
            <ProductFormSelect
              name={`${name}.province`}
              refData={province}
              label={getText('province')}
              placeholder={getText('select.placeholder')}
              formatErrorMessage={formatErrorMessage}
              sort
            />
          </Col>
        </Row>
      )}

      {name === 'currentAddress' && (
        <Row>
          {/* Start Date */}
          <Col xs={12} md={6} data-test="field_startDate">
            <ProductFormDateFields
              name="currentAddressStartDate"
              label={getText('startDate')}
              placeholder={getText('select.placeholder')}
              formatErrorMessage={formatErrorMessage}
            />
          </Col>

          {/* Ownership */}
          <Col xs={12} md={6} data-test="field_ownership">
            <ProductFormSelect
              name="currentAddressOwnership"
              label={getText('ownership')}
              placeholder={getText('select.placeholder')}
              formatErrorMessage={formatErrorMessage}
            />
          </Col>
        </Row>
      )}

      {/* Help Text */}
      {helpText && (
        <Text data-test="fields_manual_address_Information" className="address_help mc-mb-small">
          {helpText}
        </Text>
      )}

      {/* Cancel Manual Mode Button */}
      <ProductFormField name={name} component={CancelButton} label={getText('cancelManualMode')} />
    </div>
  );
};

export default ManualAddressForm;
