import React from 'react';
import { TransferAccountDataType, TransferFormData } from '../../types';
import { ProductFormCheckbox } from 'components/productFormFields';
import { useContainerCtx } from 'providers/containerCtx/ContainerCtx';
import { ACCOUNT_SOURCE } from '../../constants';
import { ErrorData } from '../../../../types/interfaces';

type TransferAccountsFilteredProps = {
  sourceCd: ACCOUNT_SOURCE;
  ChildrenMapAccountItem?: React.FC<{
    index: number;
    accountData: TransferAccountDataType;
    error?: Partial<Record<keyof TransferAccountDataType, ErrorData>>;
  }>;
};

const TransferAccountsFiltered: React.FC<TransferAccountsFilteredProps> = ({ sourceCd, ChildrenMapAccountItem }) => {
  const {
    formValues,
    locale,
    getTextGlobal,
    formatErrorMessage,
    genericProps: { error },
  } = useContainerCtx<TransferFormData>();

  const { accounts } = formValues;
  const containerTestId = `transfer-accounts-${sourceCd}`;

  return (
    <div data-test={containerTestId}>
      {accounts?.map((accountData, i) => {
        const { accountType, currency, registeredAgmtFlg, disabled = false, transferSourceCd, transfer } = accountData;

        const registeredAgmtFlgLabel = getTextGlobal(registeredAgmtFlg);
        const checkboxLabel = `${accountType[locale]} ${currency[locale]} ${registeredAgmtFlgLabel}`;
        const displayAccount = transferSourceCd && transferSourceCd === sourceCd;
        const accountTestId = `${containerTestId}-${accountType['code']}-${currency[locale]}`;

        if (!displayAccount) return null;
        return (
          <div key={accountTestId} className="transfer-checkbox mc-mb-normal" data-test={accountTestId}>
            <ProductFormCheckbox
              name={`accounts[${i}].transfer`}
              formatErrorMessage={formatErrorMessage()}
              label={checkboxLabel}
              disabled={disabled}
              className={disabled ? 'disabled-account' : ''}
            />

            {transfer && ChildrenMapAccountItem && (
              <div data-test={`${accountTestId}-children`} className={'mc-ml-large mc-mt-normal'}>
                <ChildrenMapAccountItem accountData={accountData} index={i} error={error?.['accounts']?.[i]} />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default TransferAccountsFiltered;
