import React, { Fragment } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import { BeneficiaryInformationSlice } from '../types';
import { getTextFactory } from 'utils/TextUtils';
import { InjectedIntl, injectIntl } from 'react-intl';
import SummarySection from './SummarySection';
import { BFF_STEPS_IDS } from 'utils/stepsConstants';
import { getBeneficiaryInformationSlice } from '../selectors';
import { formValueNames } from 'containers/BeneficiaryInformation/types';
import SummaryField from './SummaryField';
import { Heading } from '@nbc-design/heading';
import { Divider } from '@nbc-design/divider';

export type BeneficiaryInformationSectionPropsFromState = {
  beneficiaryInformationSlice: BeneficiaryInformationSlice;
};

export type BeneficiaryInformationSectionProps = BeneficiaryInformationSectionPropsFromState & {
  goToStep: (stepId: string) => string;
  intl: InjectedIntl;
};

export const BeneficiaryInformationSection = ({
  intl,
  beneficiaryInformationSlice,
  goToStep,
}: BeneficiaryInformationSectionProps) => {
  const getText = getTextFactory(intl, 'beneficiaryInformation');
  const getSummaryText = getTextFactory(intl, 'summary.beneficiaryInformation');

  if (!beneficiaryInformationSlice) {
    return null;
  }

  const { beneficiaries, firstName, lastName }: BeneficiaryInformationSlice = beneficiaryInformationSlice;

  const renderValue = (beneficiary, valueName) => {
    if (beneficiary[valueName]) {
      // Display order defined by key order in formValueNames
      switch (valueName) {
        case formValueNames.GENDER:
        case formValueNames.BIRTH_DATE:
          return (
            <Col xs={12} sm={6} key={valueName}>
              <SummaryField label={getText(valueName)} dataTest={`beneficiaries-information-recap-field-${valueName}`}>
                {beneficiary[valueName][intl.locale]}
              </SummaryField>
            </Col>
          );
        case formValueNames.ADDRESS:
          return (
            <Col xs={12} key={valueName}>
              <SummaryField label={getText(valueName)} dataTest={`beneficiaries-information-recap-field-${valueName}`}>
                {beneficiary[valueName][intl.locale]}
              </SummaryField>
            </Col>
          );
        case formValueNames.PARENT_RELATIONSHIP:
          return (
            <Col xs={12} key={valueName}>
              <SummaryField label={getText(valueName)} dataTest={`beneficiaries-information-recap-field-${valueName}`}>
                {getText('theBeneficiary')} {beneficiary[valueName][intl.locale].toLowerCase()}
              </SummaryField>
            </Col>
          );
        case formValueNames.RELATIONSHIP:
          return (
            <Col xs={12} key={valueName}>
              <SummaryField label={getText(valueName)} dataTest={`beneficiaries-information-recap-field-${valueName}`}>
                {firstName} {lastName} {beneficiary[valueName][intl.locale]?.toLowerCase()} {getText('theBeneficiary')}
              </SummaryField>
            </Col>
          );

        case formValueNames.GRANT_REQUEST:
          return (
            <Col xs={12} key={valueName}>
              <SummaryField label={getText(valueName)} dataTest={`beneficiaries-information-recap-field-${valueName}`}>
                {beneficiary[valueName] ? getText(`${valueName}.yes`) : getText(`${valueName}.no`)}
              </SummaryField>
            </Col>
          );
        case formValueNames.PERCENT:
          return (
            <Col xs={12} key={valueName}>
              <SummaryField label={getText(valueName)} dataTest={`beneficiaries-information-recap-field-${valueName}`}>
                {beneficiary[valueName]}%
              </SummaryField>
            </Col>
          );
        case formValueNames.FIRST_NAME:
        case formValueNames.MIDDLE_NAME:
        case formValueNames.LAST_NAME:
        case formValueNames.PARENT_FIRST_NAME:
        case formValueNames.PARENT_MIDDLE_NAME:
        case formValueNames.PARENT_LAST_NAME:
        case formValueNames.SIN:
        default:
          return (
            <Col xs={12} sm={6} key={valueName}>
              <SummaryField label={getText(valueName)} dataTest={`beneficiaries-information-recap-field-${valueName}`}>
                {beneficiary[valueName]}
              </SummaryField>
            </Col>
          );
      }
    } else {
      return <Fragment key={valueName}></Fragment>;
    }
  };

  const renderBeneficiary = (beneficiary, index, array) => (
    <Fragment key={btoa(beneficiary.firstName)}>
      {array.length > 1 && (
        <Heading type="h3" size={5} data-test="beneficiary_heading_title">
          {`${getText('beneficiary')} ${index + 1}`}
        </Heading>
      )}
      <Row>
        {Object.values(formValueNames).map((valueName) => renderValue(beneficiary, valueName))}
        {index !== array.length - 1 && (
          <Col xs={4} xsOffset={4} className="mc-mt-xsmall mc-mb-large">
            <Divider dataTest="beneficiaries-information-recap-divider" />
          </Col>
        )}
      </Row>
    </Fragment>
  );

  const renderContent = () => (
    <div data-test="beneficiaries-information-recap-content section__content">
      {beneficiaries?.map((beneficiary, index, array) => renderBeneficiary(beneficiary, index, array))}
    </div>
  );

  return (
    <SummarySection
      intl={intl}
      name="beneficiaryInformation"
      title={getSummaryText('pageTitle')}
      stepId={BFF_STEPS_IDS.BENEFICIARYINFORMATION}
      goToStep={goToStep}
      renderSectionContent={renderContent}
      editable={beneficiaryInformationSlice && beneficiaryInformationSlice.editable}
    />
  );
};

const mapStateToProps = (state: BeneficiaryInformationSlice) => {
  return {
    beneficiaryInformationSlice: getBeneficiaryInformationSlice(state),
  };
};

export default connect<BeneficiaryInformationSectionPropsFromState, {}, BeneficiaryInformationSectionProps>(
  mapStateToProps,
)(injectIntl(BeneficiaryInformationSection));
