import { CURRENCIES } from 'utils/constants';
import { ACCOUNT_TYPE, CAD_ACCOUNTS, USD_DISABLED_TOOLTIP_ACCOUNTS } from './constants';
import { AccountData, AccountOptionStatus } from './types';
import { fromState } from 'globalRedux/store';
import { getTextFactoryReturnType } from '../../utils/TextUtils';

export const isAccountOfType = (accountValue: AccountData, accountType: ACCOUNT_TYPE): boolean => {
  return accountValue.code === accountType;
};

export const isAccountSelected = (account: AccountData, currency?: CURRENCIES): boolean =>
  account.values
    .filter((currencyData) => typeof currency === 'undefined' || currencyData.code === currency)
    .some(({ value }) => value);

export const isAccountOfTypeSelected = (
  accounts: AccountData[],
  accountType: ACCOUNT_TYPE,
  currency?: CURRENCIES,
): boolean =>
  accounts
    .filter((accountData) => isAccountOfType(accountData, accountType))
    .some((accountData) => isAccountSelected(accountData, currency));

export const isAccountDisabled = (accountType: ACCOUNT_TYPE, accounts: AccountData[]): AccountOptionStatus => {
  const currenciesDefaults = {
    CAD: { disabled: false },
    USD: { disabled: false },
  };

  const allCurrenciesDisabled = {
    CAD: { disabled: true },
    USD: { disabled: true },
  };

  const usCurrenciesDisabled = {
    CAD: { disabled: false },
    USD: { disabled: true },
  };

  // const caCurrenciesDisabled = {
  //   CAD: { disabled: true },
  //   USD: { disabled: false },
  // };

  if (accountType === ACCOUNT_TYPE.CASH) {
    const marginAccount = accounts.find((acc) => isAccountOfType(acc, ACCOUNT_TYPE.MARGIN));
    const marginNsAccount = accounts.find((acc) => isAccountOfType(acc, ACCOUNT_TYPE.MGNS));
    if (
      (marginAccount && isAccountSelected(marginAccount)) ||
      (marginNsAccount && isAccountSelected(marginNsAccount))
    ) {
      return { message: 'accountCASH', currencies: allCurrenciesDisabled };
    }
  }

  if (accountType === ACCOUNT_TYPE.MARGIN || accountType === ACCOUNT_TYPE.MGNS) {
    const cashAccount = accounts.find((acc) => isAccountOfType(acc, ACCOUNT_TYPE.CASH));
    if (typeof cashAccount !== 'undefined' && isAccountSelected(cashAccount)) {
      return { message: `account${accountType}`, currencies: allCurrenciesDisabled };
    }
  }

  if (accountType === ACCOUNT_TYPE.MARGIN) {
    const mgnsAccount = accounts.find((acc) => isAccountOfType(acc, ACCOUNT_TYPE.MGNS));
    if (typeof mgnsAccount !== 'undefined') {
      return mgnsAccount.values
        .filter((currency) => currency.value)
        .reduce<AccountOptionStatus>(
          (status, currency) => {
            status.currencies[currency.code] = { disabled: currency.value };
            return status;
          },
          {
            message: null,
            currencies: currenciesDefaults,
          },
        );
    }
  }

  if (accountType === ACCOUNT_TYPE.FHSA) {
    const fhsaAccount = accounts.find((acc) => isAccountOfType(acc, ACCOUNT_TYPE.FHSA));
    if (typeof fhsaAccount !== 'undefined') {
      return { message: '', currencies: usCurrenciesDisabled };
    }
  }

  // Disable CAD checkbox when USD is selected for given account types
  if ([ACCOUNT_TYPE.RRIF].includes(accountType)) {
    return { message: '', currencies: usCurrenciesDisabled };
    // Disabled while awwaiting enabler story for RRIF USD
    // const RRIFAccount = accounts.find((account) => isAccountOfType(account, ACCOUNT_TYPE.RRIF));
    // const isUSDSelected = RRIFAccount?.values?.find((value) => value.code === CURRENCIES.USD)?.value;
    // if (isUSDSelected) {
    //   return {
    //     message: '',
    //     currencies: caCurrenciesDisabled,
    //   };
    // } else {
    //   return {
    //     message: '',
    //     currencies: currenciesDefaults,
    //   };
    // }
  }

  if (CAD_ACCOUNTS.includes(accountType)) {
    return { message: '', currencies: usCurrenciesDisabled };
  }

  return { message: null, currencies: currenciesDefaults };
};

export const isAccountSectionOpen = (accountType: ACCOUNT_TYPE): boolean => {
  return !!fromState<boolean, undefined>(`reduxFormReducer.baoForms.values.${accountType}-display-section`, false);
};

export const getAccountTooltip = (
  accountType: ACCOUNT_TYPE,
  isDisabled: boolean,
  generateTooltip: (name: string, content: string) => JSX.Element,
  getText: getTextFactoryReturnType,
): JSX.Element | undefined => {
  if (USD_DISABLED_TOOLTIP_ACCOUNTS.includes(accountType) && isDisabled) {
    return generateTooltip(`accounts.[${accountType}].currency-choice`, getText('USDDisabled.tooltip'));
  }
};
