import { injectIntl } from 'react-intl';
import SpecificsBankingInfo from './SpecificsBankingInfo';
import { formValues } from 'bnc-react-forms';
import config from './config';
import * as validation from './validation';

export const VALIDATION = validation;
export const CONFIG = config;

export default injectIntl(
  formValues(
    'bankingAccounts',
    'safeIdFinancialExp',
    'confirmedBankingAccounts',
    'programParticipation',
    'maxBankingAccounts',
  )(SpecificsBankingInfo),
);
