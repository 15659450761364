export enum CLIENT_TYPE {
  INDIV = 'INDIV',
  JOINT = 'JOINT',
}

export enum ACCOUNT_TYPE {
  MARGIN = 'MGN',
  MGNS = 'MGNS',
  CASH = 'CASH',
  RRSP = 'RRSP',
  TFSA = 'TFSA',
  FHSA = 'FHSA',
  LIRA = 'LIRA',
  LRSP = 'LRSP',
  RLSP = 'RLSP',
  SRRSP = 'SRRSP',
  RRIF = 'RRIF',
  SRRIF = 'SRRIF',
  LIF = 'LIF',
  LRIF = 'LRIF',
  PRIF = 'PRIF',
  RLIF = 'RLIF',
  RESP = 'RESP',
}

export const NON_REGISTERED_ACCOUNT_TYPES = [ACCOUNT_TYPE.MARGIN, ACCOUNT_TYPE.MGNS, ACCOUNT_TYPE.CASH];

export const REGISTERED_ACCOUNT_TYPES = [
  ACCOUNT_TYPE.RRSP,
  ACCOUNT_TYPE.TFSA,
  ACCOUNT_TYPE.FHSA,
  ACCOUNT_TYPE.LIRA,
  ACCOUNT_TYPE.LRSP,
  ACCOUNT_TYPE.RLSP,
  ACCOUNT_TYPE.SRRSP,
  ACCOUNT_TYPE.RRIF,
  ACCOUNT_TYPE.SRRIF,
  ACCOUNT_TYPE.LIF,
  ACCOUNT_TYPE.LRIF,
  ACCOUNT_TYPE.PRIF,
  ACCOUNT_TYPE.RLIF,
  ACCOUNT_TYPE.RESP,
];

export const AUTOMATED_DECISION_ACCOUNT_TYPES = [ACCOUNT_TYPE.MARGIN, ACCOUNT_TYPE.MGNS];

export const CAD_ACCOUNTS = [
  ACCOUNT_TYPE.RRIF,
  ACCOUNT_TYPE.SRRIF,
  ACCOUNT_TYPE.LIF,
  ACCOUNT_TYPE.RLIF,
  ACCOUNT_TYPE.LRIF,
  ACCOUNT_TYPE.PRIF,
  ACCOUNT_TYPE.RESP,
];

export const USD_REQUIRES_CAD_ACCOUNTS = [ACCOUNT_TYPE.RRIF];

export const USD_DISABLED_TOOLTIP_ACCOUNTS = [
  ACCOUNT_TYPE.RESP,
  ACCOUNT_TYPE.FHSA,
  ACCOUNT_TYPE.RRIF,
  ACCOUNT_TYPE.SRRIF,
  ACCOUNT_TYPE.LIF,
  ACCOUNT_TYPE.LRIF,
  ACCOUNT_TYPE.RLIF,
  ACCOUNT_TYPE.PRIF,
];

export const ACCOUNTS_WITH_PROVINCE = [ACCOUNT_TYPE.LIF];
