import React, { FunctionComponent, ReactNode } from 'react';
import { InjectedIntl } from 'react-intl';
import Title from 'components/Title';
import { getTextFactory } from 'utils/TextUtils';
import { PaymentDetail, PaymentInformationErrorData } from './types';
import { GenericProps, ProductFormFieldChangeHandler } from 'types/interfaces';

import './style.scss';
import { InfoFillColor } from '@nbc-design/icons/lib/web/InfoFillColor';
import { Text } from '@nbc-design/text';
import AccountPaymentDetails from './components/AccountPaymentDetails';
import { Divider } from '@nbc-design/divider';

export type PaymentInformationProps = GenericProps & {
  intl: InjectedIntl;
  payments: PaymentDetail[];
  error?: PaymentInformationErrorData;
  change: ProductFormFieldChangeHandler;
};

const PaymentInformation: FunctionComponent<PaymentInformationProps> = (props: PaymentInformationProps) => {
  const { intl, productForm, payments, change } = props;

  const getText = getTextFactory(intl, 'paymentInformation');

  return (
    <div id="PaymentInformation_form">
      <Title label={getText('pageTitle')} />

      <div className="title-info-container">
        <div>
          <InfoFillColor size="small" title="info-fill-color" />
        </div>
        <div>
          <Text>{getText('titleInfo')}</Text>
        </div>
      </div>

      {payments &&
        payments.map(
          (paymentDetail: PaymentDetail, index: number): ReactNode => (
            <div key={`AccountPaymentDetail_${index}`}>
              <AccountPaymentDetails
                index={index}
                intl={intl}
                schema={productForm.schema}
                change={change}
                paymentDetail={paymentDetail}
              />
              {payments && index + 1 < payments.length && (
                <Divider appearance="dark" size="small" className="account-divider" />
              )}
            </div>
          ),
        )}
    </div>
  );
};

export default PaymentInformation;
