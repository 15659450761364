import { ACCOUNT_TYPE } from 'containers/AccountsStep1/constants';
import { AccountPaymentTypesConfig, PaymentTypeCd } from './types';
import { PROVINCES } from 'utils/constants';

export enum MONTH {
  JANUARY = '1',
  FEBRUARY = '2',
  MARCH = '3',
  APRIL = '4',
  MAY = '5',
  JUNE = '6',
  JULY = '7',
  AUGUST = '8',
  SEPTEMBER = '9',
  OCTOBER = '10',
  NOVEMBER = '11',
  DECEMBER = '12',
}

export const MONTHLY: string[] = [MONTH.JANUARY];
export const QUARTERLY: string[] = [MONTH.JANUARY, MONTH.FEBRUARY, MONTH.MARCH];
export const BIANNUALLY: string[] = [MONTH.JANUARY, MONTH.FEBRUARY, MONTH.MARCH, MONTH.APRIL, MONTH.MAY, MONTH.JUNE];
export const ANNUALLY: string[] = [
  MONTH.JANUARY,
  MONTH.FEBRUARY,
  MONTH.MARCH,
  MONTH.APRIL,
  MONTH.MAY,
  MONTH.JUNE,
  MONTH.JULY,
  MONTH.AUGUST,
  MONTH.SEPTEMBER,
  MONTH.OCTOBER,
  MONTH.NOVEMBER,
  MONTH.DECEMBER,
];

/*
 * As described in 'BAO-RA-1000-36 Choix potentiels pour établir le montant des versements'
 * https://wiki.bnc.ca/pages/viewpage.action?pageId=1866032643
 */
export const accountPaymentTypesConfig: AccountPaymentTypesConfig[] = [
  { accountType: ACCOUNT_TYPE.RRIF, paymentTypes: [PaymentTypeCd.MINIMUM, PaymentTypeCd.FIXED] },
  { accountType: ACCOUNT_TYPE.SRRIF, paymentTypes: [PaymentTypeCd.MINIMUM, PaymentTypeCd.FIXED] },
  {
    accountType: ACCOUNT_TYPE.LIF,
    paymentTypes: [PaymentTypeCd.MINIMUM, PaymentTypeCd.FIXED, PaymentTypeCd.MAXIMUM, PaymentTypeCd.TEMPORARY],
    province: [PROVINCES.QC],
  },
  {
    accountType: ACCOUNT_TYPE.LIF,
    paymentTypes: [PaymentTypeCd.MINIMUM, PaymentTypeCd.FIXED, PaymentTypeCd.MAXIMUM],
  },
  { accountType: ACCOUNT_TYPE.RLIF, paymentTypes: [PaymentTypeCd.MINIMUM, PaymentTypeCd.FIXED, PaymentTypeCd.MAXIMUM] },
  { accountType: ACCOUNT_TYPE.LRIF, paymentTypes: [PaymentTypeCd.MINIMUM, PaymentTypeCd.FIXED, PaymentTypeCd.MAXIMUM] },
  { accountType: ACCOUNT_TYPE.PRIF, paymentTypes: [PaymentTypeCd.MINIMUM, PaymentTypeCd.FIXED] },
];
