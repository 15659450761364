import { ACCOUNT_TYPE } from 'containers/AccountsStep1/constants';
import { ErrorData } from '../../types/interfaces';
import { GenericErrorTypes } from '../../utils/validation';
import { PROVINCES } from 'utils/constants';

export type PaymentInformationFormData = {
  payments: PaymentDetail[];
};

export type PaymentInformationErrorData = {
  frequencyCd?: ErrorData;
  startMonth?: ErrorData;
  dayOfMonth?: ErrorData;
  paymentTypeCd?: ErrorData;
  referenceAgeTypeCd?: ErrorData;
  transferModeCd?: ErrorData;
  amount?: ErrorData;
  transferAccountNumber?: ErrorData;
};

export type PaymentDetail = {
  accountType: AccountType;
  currency: Currency;
  frequencyCd: string;
  startMonth: number;
  dayOfMonth: number;
  paymentTypeCd: string;
  amount?: number;
  referenceAgeTypeCd: string;
  transferModeCd: string;
  transferAccountNumber?: string;
  province?: ProvinceCd;
};

export type ProvinceCd = {
  en: string;
  fr: string;
  code: PROVINCES;
};

export type Currency = {
  en: string;
  fr: string;
  code: string;
};

export interface PaymentInformationErrorsDictionary extends GenericErrorTypes {
  MUST_BE_A_VALID_ACCOUNT_NUMBER: ErrorData;

  [name: string]: ErrorData;
}

export type AccountType = {
  en: string;
  fr: string;
  code: string;
};

export type PaymentValidationData = {
  payments?: PaymentInformationErrorData | object;
};

export enum PaymentTypeCd {
  'MINIMUM' = '01',
  'FIXED' = '02',
  'MAXIMUM' = '03',
  'TEMPORARY' = '04',
}

export type AccountPaymentTypesConfig = {
  accountType: ACCOUNT_TYPE;
  paymentTypes: PaymentTypeCd[];
  province?: PROVINCES[];
};

export enum TransferModeCd {
  'EFT' = 'EFT',
  'CDBN' = 'CDBN',
}
