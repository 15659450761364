import React, { ChangeEvent, FocusEvent, useState } from 'react';
import '../styles.scss';
import { useContainerCtx } from 'providers/containerCtx/ContainerCtx';
import { Col, Row } from 'react-flexbox-grid';
import {
  ProductFormDateFields,
  ProductFormInput,
  ProductFormRadioBoolean,
  ProductFormRadioSelect,
  ProductFormSelect,
} from 'components/productFormFields';
import { get } from 'lodash';
import { AllRefData, ProductFormFieldChangeHandler, RefData } from 'types/interfaces';
import { formatAddress } from 'components/productFormFields/ProductFormAddressSelect/utils';
import { CategoryTaxes } from '@nbc-design/icons/lib/web/CategoryTaxes';
import { BeneficiaryFormValues, BeneficiaryGender, BeneficiaryRelationTypeCd, formValueNames } from '../types';
import AddressInput from 'components/AddressInput';
import { Fieldset } from '@nbc-design/fieldset';
import { Text } from '@nbc-design/text';
import { Address } from 'components/productFormFields/ProductFormAddressSelect/types';
import { useBeneficiaryInformationCtx } from '../providers/BeneficiaryInformationCtx';
import { Alert } from '@nbc-design/alert';
import BeneficiaryFieldList from './BeneficiaryFieldList';
import validation from '../validation';
import BeneficiaryDocumentAlert from './BeneficiaryDocumentAlert';
import { getState } from 'globalRedux/store';
import { socialInsuranceFormatter } from 'utils';
import { filterRelationshipOptions } from '../utils';
import { RespPlanTypeCd } from 'containers/AccountsStep3/types';
import { getRefData } from 'utils/productFormUtils';

export type BeneficiaryModalProps = {
  cancel: Function;
};

const BeneficiaryForm: React.FC<BeneficiaryModalProps> = () => {
  const {
    getText,
    getTextGlobal,
    getAccessibilityText,
    formValues,
    formatErrorMessage,
    bncReactForm,
    genericProps,
  } = useContainerCtx<BeneficiaryFormValues>();
  const { beneficiaryToEdit } = useBeneficiaryInformationCtx();
  const { change } = bncReactForm;
  const { formData, holder, eligibleRelationTypes, account } = formValues;
  const {
    useHolderAddress,
    currentAddress,
    requestGovGrt,
    hasIncompleteInfo,
    isConfirmed,
    relationTypeCd,
    socialInsuranceNumber: SIN,
  } = formData ?? {};
  const { respPlanTypeCd } = account;
  const { currentAddress: holderAddress } = holder ?? {};

  const country: RefData[] = get(
    bncReactForm.productForm,
    'schema.definitions.AddressUI.properties.country.refData',
    [],
  );

  const provinceCA: RefData[] = get(
    bncReactForm.productForm,
    'schema.definitions.AddressUI.properties.provinceCA.refData',
    [],
  );

  const provinceUS: RefData[] = get(
    bncReactForm.productForm,
    'schema.definitions.AddressUI.properties.provinceUS.refData',
    [],
  );
  const mailingAddressRefData: AllRefData = { country, provinceCA, provinceUS };
  const formatedCurrentAddress = formatAddress((currentAddress! as unknown) as Address, false);
  const formatedHolderAddres = formatAddress((holderAddress! as unknown) as Address, false);
  const isSameAddress = formatedCurrentAddress === formatedHolderAddres;
  const errors = get(getState(), 'reduxFormReducer.baoForms.syncErrors.formData');
  const fields = get(getState(), 'reduxFormReducer.baoForms.fields.formData');
  const addInputPadding = fields?.[formValueNames.RELATIONSHIP]?.touched && errors?.[formValueNames.RELATIONSHIP];
  const genderRefData = getRefData(genericProps, formValueNames.GENDER)?.filter(
    (ref: RefData) => !!BeneficiaryGender[ref?.en?.label?.toUpperCase()!],
  );
  const validationResponse = validation(formValues);

  const relationshipOptions = filterRelationshipOptions(
    genericProps.productForm.schema?.definitions?.RespBeneficiaryDetail?.properties?.[formValueNames.RELATIONSHIP]
      ?.refData,
    eligibleRelationTypes,
  );

  const [maskedSIN, setMaskedSIN] = useState(
    (SIN && SIN?.substring(0, 7).replace(new RegExp('[0-9]', 'g'), '*') + ' ' + SIN?.substring(8)) ?? '',
  );

  const handleChangeSIN = (event: ChangeEvent<HTMLInputElement>): void => {
    event?.preventDefault && event.preventDefault();
    const { name, value } = event.target;
    name && change(name, socialInsuranceFormatter(value));
  };

  const handleFocusSIN = (event: FocusEvent<HTMLInputElement>): void => {
    event?.preventDefault && event.preventDefault();
    setMaskedSIN('');
  };

  const handleBlurSIN = (event: FocusEvent<HTMLInputElement>): void => {
    event?.preventDefault && event.preventDefault();
    const { value } = event.target;
    const formattedMmaskedSIN = value.substring(0, 7).replace(new RegExp('[0-9]', 'g'), '*') + ' ' + value.substring(8);
    setMaskedSIN(formattedMmaskedSIN);
  };

  React.useEffect(() => {
    if (!!formData) {
      if (useHolderAddress === undefined) {
        if (currentAddress !== undefined) {
          if (!isSameAddress) {
            change(`formData.useHolderAddress`, false);
          } else {
            change(`formData.useHolderAddress`, true);
          }
        } else {
          change(`formData.useHolderAddress`, true);
        }
      }
    }
  }, [change, currentAddress, formData, isSameAddress, useHolderAddress]);

  React.useEffect(() => {
    if (isConfirmed && !hasIncompleteInfo && validationResponse?.account) {
      change('formData.hasIncompleteInfo', true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  React.useEffect(() => {
    if (useHolderAddress) {
      change(`formData.${formValueNames.ADDRESS}`, holderAddress);
    } else if (isSameAddress) {
      // Reset address field when selecting other address
      change(`formData.${formValueNames.ADDRESS}`, {});
    }
  }, [change, holderAddress, isSameAddress, useHolderAddress]);

  if (!formData) return null;
  return (
    <>
      {hasIncompleteInfo && (
        <Alert
          className={'mc-pt-normal'}
          appearance={'error'}
          description={getText('missingInformation')}
          isClosable={false}
          ariaLabelIcon={getAccessibilityText('icon.error')}
          type={'p'}
        />
      )}
      <div className={'mc-pt-normal modal-form-container'}>
        {isConfirmed && !hasIncompleteInfo ? (
          <BeneficiaryFieldList />
        ) : (
          <>
            <Row>
              {[formValueNames.FIRST_NAME, formValueNames.MIDDLE_NAME, formValueNames.LAST_NAME].map(
                (name: formValueNames) => (
                  <Col xs={12} sm={6} key={name} className={'mc-mb-xsmall'}>
                    <ProductFormInput
                      name={`formData.${name}`}
                      label={getText(name)}
                      placeholder={getText(`${name}.placeholder`)}
                      optionalText={[formValueNames.MIDDLE_NAME].includes(name) ? getTextGlobal('optional') : ''}
                      customSchema={
                        genericProps.productForm.schema?.definitions?.RespBeneficiaryDetail?.properties?.[name]
                      }
                      formatErrorMessage={formatErrorMessage()}
                    />
                  </Col>
                ),
              )}
            </Row>
            <Row>
              <Col xs={12} sm={8} className={'mc-mb-xsmall'}>
                <ProductFormDateFields
                  name={`formData.${formValueNames.BIRTH_DATE}`}
                  customSchema={
                    genericProps.productForm.schema?.definitions?.RespBeneficiaryDetail?.properties?.[
                      formValueNames.BIRTH_DATE
                    ]
                  }
                  label={getText(formValueNames.BIRTH_DATE)}
                  formatErrorMessage={formatErrorMessage()}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={8} className={'mc-mb-xsmall'}>
                <ProductFormInput
                  label={getText(formValueNames.SIN)}
                  data-test={'input_socialInsuranceNumber'}
                  name={`formData.${formValueNames.SIN}`}
                  ariaLabel={getText(formValueNames.SIN)}
                  helpText={getText(`${formValueNames.SIN}.description`)}
                  customSchema={
                    genericProps.productForm.schema?.definitions?.RespBeneficiaryDetail?.properties?.[
                      formValueNames.SIN
                    ]
                  }
                  handleOnBlur={handleBlurSIN}
                  handleOnFocus={handleFocusSIN}
                  handleOnChange={handleChangeSIN}
                  inputRawValue={maskedSIN}
                  formatErrorMessage={formatErrorMessage()}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} className={'mc-mb-xsmall'}>
                <ProductFormRadioSelect
                  refData={genderRefData}
                  name={`formData.${formValueNames.GENDER}`}
                  customSchema={
                    genericProps.productForm.schema?.definitions?.RespBeneficiaryDetail?.properties?.[
                      formValueNames.GENDER
                    ]
                  }
                  inline
                  label={getText(formValueNames.GENDER)}
                  formatErrorMessage={formatErrorMessage()}
                  helpText={getText(`${formValueNames.GENDER}.description`)}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={8} className={'mc-mb-xsmall'}>
                <ProductFormRadioBoolean
                  data-test={'useHolderAddress-input'}
                  name={`formData.useHolderAddress`}
                  label={getText('useHolderAddress')}
                  yesLabel={getText('myAddress')}
                  noLabel={getText('anotherAddress')}
                  yesText={formatedHolderAddres}
                />
              </Col>
            </Row>

            {!useHolderAddress && (
              <Row>
                <Col xs={12} className={'mc-mb-xsmall'}>
                  <AddressInput
                    name={`formData.${formValueNames.ADDRESS}`}
                    label={getText(formValueNames.ADDRESS)}
                    change={(change as unknown) as ProductFormFieldChangeHandler}
                    textPrefix={''}
                    allRefData={mailingAddressRefData}
                  />
                </Col>
              </Row>
            )}
          </>
        )}

        <Row className={'mc-mb-xsmall'}>
          <Col xs={12}>
            <Fieldset legend={getText('relationToBeneficiary')}>
              <Row className={'mc-m-0'}>
                <Col xs={12} sm={4} className={'mc-d-flex mc-flex-row table-grid-padding'}>
                  <Text
                    className={`mc-justify-self-start mc-align-self-center relationship-text ${
                      addInputPadding ? 'select-text-on-error' : ''
                    }`}
                  >
                    {`${holder?.firstName ? ` ${holder?.firstName}` : ''}${
                      holder?.middleName ? ` ${holder?.middleName}` : ''
                    }${holder?.lastName ? ` ${holder?.lastName}` : ''}`}
                  </Text>
                </Col>
                <Col
                  xs={12}
                  sm={4}
                  className={
                    'mc-d-flex mc-flex-row mc-justify-content-start mc-align-items-center sm-table-grid-padding'
                  }
                >
                  <ProductFormSelect
                    className={'mc-mb-0 mc-w-100'}
                    name={`formData.${formValueNames.RELATIONSHIP}`}
                    customSchema={
                      genericProps.productForm.schema?.definitions?.RespBeneficiaryDetail?.properties?.[
                        formValueNames.RELATIONSHIP
                      ]
                    }
                    refData={relationshipOptions}
                    placeholder={`${getText('SelectDefaultOption')}`}
                    formatErrorMessage={formatErrorMessage()}
                  />
                </Col>
                <Col xs={12} sm={4} className={'mc-d-flex mc-flex-row table-grid-padding'}>
                  <Text
                    className={`mc-justify-self-start mc-align-self-center relationship-text ${
                      addInputPadding ? 'select-text-on-error' : ''
                    }`}
                  >
                    {getText('theBeneficiary')}
                  </Text>
                </Col>
              </Row>
            </Fieldset>
          </Col>
        </Row>

        {relationTypeCd &&
          ![BeneficiaryRelationTypeCd.PARENT, BeneficiaryRelationTypeCd.TUTOR].includes(relationTypeCd) && (
            <Fieldset legend={getText('beneficiaryParent')}>
              <Row className={'mc-mb-xsmall mc-m-0'}>
                <Col xs={12} sm={4} className={'mc-d-flex mc-flex-row table-grid-padding'}>
                  <Text
                    className={`mc-justify-self-start mc-align-self-center relationship-text ${
                      addInputPadding ? 'select-text-on-error' : ''
                    }`}
                  >
                    {getText('theBeneficiary')}
                  </Text>
                </Col>
                <Col
                  xs={12}
                  sm={4}
                  className={
                    'mc-d-flex mc-flex-row mc-justify-content-start mc-align-items-center sm-table-grid-padding'
                  }
                  key={formValueNames.PARENT_RELATIONSHIP}
                >
                  <ProductFormSelect
                    className={'mc-mb-0 mc-w-100'}
                    name={`formData.${formValueNames.PARENT_RELATIONSHIP}`}
                    customSchema={
                      genericProps.productForm.schema?.definitions?.RespBeneficiaryDetail?.properties?.[
                        formValueNames.PARENT_RELATIONSHIP
                      ]
                    }
                    refData={
                      genericProps.productForm.schema?.definitions?.RespBeneficiaryDetail?.properties?.[
                        formValueNames.PARENT_RELATIONSHIP
                      ]?.refData
                    }
                    placeholder={`${getText('SelectDefaultOption')}`}
                    formatErrorMessage={formatErrorMessage()}
                  />
                </Col>
                <Col xs={12} sm={4} className={'mc-d-flex mc-flex-row table-grid-padding'}></Col>
              </Row>
              <Row className={'mc-mb-xsmall'}>
                <Col xs={12} sm={6} key={formValueNames.PARENT_FIRST_NAME} className={'mc-mb-xsmall'}>
                  <ProductFormInput
                    name={`formData.${formValueNames.PARENT_FIRST_NAME}`}
                    label={getText(formValueNames.PARENT_FIRST_NAME)}
                    placeholder={getText(`${formValueNames.PARENT_FIRST_NAME}.placeholder`)}
                    customSchema={
                      genericProps.productForm.schema?.definitions?.RespBeneficiaryDetail?.properties?.[
                        formValueNames.PARENT_FIRST_NAME
                      ]
                    }
                    formatErrorMessage={formatErrorMessage()}
                  />
                </Col>
                <Col xs={12} sm={6} key={formValueNames.PARENT_MIDDLE_NAME} className={'mc-mb-xsmall'}>
                  <ProductFormInput
                    name={`formData.${formValueNames.PARENT_MIDDLE_NAME}`}
                    label={getText(formValueNames.PARENT_MIDDLE_NAME)}
                    placeholder={getText(`${formValueNames.PARENT_MIDDLE_NAME}.placeholder`)}
                    customSchema={
                      genericProps.productForm.schema?.definitions?.RespBeneficiaryDetail?.properties?.[
                        formValueNames.PARENT_MIDDLE_NAME
                      ]
                    }
                    formatErrorMessage={formatErrorMessage()}
                  />
                </Col>
                <Col xs={12} sm={6} key={formValueNames.PARENT_LAST_NAME} className={'mc-mb-xsmall'}>
                  <ProductFormInput
                    name={`formData.${formValueNames.PARENT_LAST_NAME}`}
                    label={getText(formValueNames.PARENT_LAST_NAME)}
                    placeholder={getText(`${formValueNames.PARENT_LAST_NAME}.placeholder`)}
                    customSchema={
                      genericProps.productForm.schema?.definitions?.RespBeneficiaryDetail?.properties?.[
                        formValueNames.PARENT_LAST_NAME
                      ]
                    }
                    formatErrorMessage={formatErrorMessage()}
                  />
                </Col>
              </Row>
            </Fieldset>
          )}

        {respPlanTypeCd === RespPlanTypeCd.FAMILY && (
          <Row className={'mc-mb-xsmall'}>
            <Col xs={12}>
              <ProductFormInput
                name={`formData.${formValueNames.PERCENT}`}
                customSchema={
                  genericProps.productForm.schema?.definitions?.RespBeneficiaryDetail?.properties?.[
                    formValueNames.PERCENT
                  ]
                }
                inputProps={{
                  length: 'xSmall',
                  icon: <CategoryTaxes size="xsmall" title="category-taxes" />,
                  min: 1,
                  max: 100,
                  type: 'number',
                }}
                className="percent-input"
                label={getText(`${formValueNames.PERCENT}`)}
                introduction={getText(`${formValueNames.PERCENT}.introduction`)}
                formatErrorMessage={formatErrorMessage()}
              />
            </Col>
          </Row>
        )}

        <Row>
          <Col xs={12}>
            <ProductFormRadioBoolean
              name={`formData.${formValueNames.GRANT_REQUEST}`}
              label={getText(formValueNames.GRANT_REQUEST)}
              customSchema={
                genericProps.productForm.schema?.definitions?.RespBeneficiaryDetail?.properties?.[
                  formValueNames.GRANT_REQUEST
                ]
              }
              tooltip={
                <Text aria-label={getAccessibilityText('tooltip.definition.trustee')}>
                  <b>{getText(`${formValueNames.GRANT_REQUEST}.tooltip.word`)}</b>
                  {getText(`${formValueNames.GRANT_REQUEST}.tooltip`)}
                </Text>
              }
              yesLabel={getText(`${formValueNames.GRANT_REQUEST}.yes`)}
              noLabel={getText(`${formValueNames.GRANT_REQUEST}.no`)}
              formatErrorMessage={formatErrorMessage()}
            />
          </Col>
        </Row>
      </div>
      {requestGovGrt && (
        <Row className="mc-mt-normal">
          <Col xs={12}>
            <BeneficiaryDocumentAlert beneficiary={formData} addCurrentToTotal={!beneficiaryToEdit} />
          </Col>
        </Row>
      )}
    </>
  );
};

export default BeneficiaryForm;
