import React, { ButtonHTMLAttributes, ChangeEvent, DetailedHTMLProps, FunctionComponent, ReactNode } from 'react';
import { InjectedIntl } from 'react-intl';
import { CurrencyInput } from '@nbc-design/currency-input';
import { CurrencyText } from '@nbc-design/currency-text';
import { FormGroup } from '@nbc-design/form-group';
import { Text } from '@nbc-design/text';
import { FormComponentProps } from '../types';
import { getErrorMessage, getLabelText, isInvalidOnChange } from '../utils';
import { CURRENCIES } from 'utils/constants';
import { getTextFactory } from 'utils/TextUtils';
import AnalyticsHelper from 'services/analytics/AnalyticsHelper';

export type ProductFormAmountInputProps = FormComponentProps & {
  intl: InjectedIntl;
  tooltip?: React.ReactNode;
  iconButton?: {
    icon: ReactNode;
    buttonProps?: DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;
    disabled?: boolean;
  };
  disabled?: boolean;
  suffix?: string;
  clickInputAction?: Function;
  fractionDigit: number;
};

const ProductFormAmountInput: FunctionComponent<ProductFormAmountInputProps> = (props: ProductFormAmountInputProps) => {
  const {
    placeholder,
    helpText,
    tooltip,
    input,
    intl,
    schema,
    isReadOnly,
    className,
    iconButton,
    disabled = false,
    suffix,
    clickInputAction,
    fractionDigit = 0,
  } = props;

  const handleChange = (value: string): void => {
    input.onChange && input.onChange(!isNaN(Number(value)) ? Number(value) : null);
  };

  const handleBlur = (event: ChangeEvent<HTMLInputElement>): void => {
    input.onBlur && input.onBlur(event.target.value ? Number(event.target.value) : null);
  };

  const invalid = isInvalidOnChange(props);
  const label = getLabelText(props);
  const getAccessibilityText = getTextFactory(intl, 'accessibility');
  const inputValue = `${input.value}`;
  const onShowTooltip = AnalyticsHelper.handleShowTooltip(input.name);

  return (
    <FormGroup
      className={className}
      label={{ text: label, htmlFor: `AmountInput-${input.name}` }}
      tooltip={{
        content: tooltip,
        ariaLabel: getAccessibilityText('information'),
        onShowTooltip,
      }}
      validate={{ hasError: invalid, errorMsg: getErrorMessage(props) }}
      description={{ text: helpText, id: `help-${input.name}` }}
    >
      {isReadOnly ? (
        <Text size={'paragraph'} className="product-form-input__read-only-value">
          <CurrencyText value={inputValue} locale={intl.locale as 'fr' | 'en'} fractionDigits={0} currency="CAD" />
        </Text>
      ) : (
        <div className="currency-input-wrapper" onClick={() => clickInputAction && clickInputAction()}>
          <CurrencyInput
            id={`AmountInput-${input.name}`}
            name={input.name}
            placeholder={placeholder}
            value={inputValue}
            onChange={handleChange}
            onBlur={handleBlur}
            maxLength={schema && schema.maxLength}
            locale={intl.locale as 'fr' | 'en'}
            fractionDigits={fractionDigit}
            currency={CURRENCIES.CAD}
            iconButton={iconButton ? iconButton : undefined}
            disabled={disabled}
            suffix={suffix}
            defaultValue={0}
          />
        </div>
      )}
    </FormGroup>
  );
};

export default ProductFormAmountInput;
