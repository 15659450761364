import get from 'lodash/get';
import { isCountryUS, isObfuscatedText, validSocialInsurance } from 'utils';
import { ERRORTYPE } from 'utils/validation';
import {
  MainResidencyErrorData,
  SecondaryResidencyErrorData,
  SINValidationData,
  TaxesErrorData,
  TaxesFormData,
} from './types';
import { COUNTRIES } from 'utils/constants';

export const validateSocialInsuranceNumber = ({
  isMainResidencyCA,
  displayNas,
  socialInsuranceNumber,
  displayCCB,
}: TaxesFormData): SINValidationData => {
  const fieldShouldBeRequired = displayNas || isMainResidencyCA || displayCCB;
  const cleanSIN =
    socialInsuranceNumber && socialInsuranceNumber.charAt(0) !== '0' && socialInsuranceNumber.charAt(0) !== '8';
  const isValid: boolean =
    !socialInsuranceNumber ||
    isObfuscatedText(socialInsuranceNumber) ||
    !!(cleanSIN && validSocialInsurance(socialInsuranceNumber));

  return {
    ...(!isValid ? { socialInsuranceNumber: ERRORTYPE.INVALIDSIN } : {}),
    ...(fieldShouldBeRequired && !socialInsuranceNumber ? { socialInsuranceNumber: ERRORTYPE.REQUIRED } : {}),
  };
};

export const getResidencyErrors = (residency: TaxesFormData | {} = {}): TaxesErrorData | undefined => {
  const ssn: string = get(residency, 'ssn', '');
  const itin: string = get(residency, 'itin', '');
  const tin: string = get(residency, 'tin', '');
  const reason: string = get(residency, 'noRevealReason', '');
  const country: string = get(residency, 'country', '');
  const isUs = isCountryUS(country);
  const identificationPresent: boolean = get(residency, 'identificationPresent', false);

  if (country === '') {
    return {
      country: ERRORTYPE.REQUIRED,
    };
  }

  if (!identificationPresent && !reason) {
    return {
      noRevealReason: ERRORTYPE.REQUIRED,
    };
  }

  if (identificationPresent && !isUs && tin === '') {
    return {
      tin: ERRORTYPE.REQUIRED,
    };
  }

  if (identificationPresent && isUs && ssn === '' && itin === '') {
    return {
      ssn: ERRORTYPE.REQUIRED,
      itin: ERRORTYPE.REQUIRED,
    };
  }

  if (identificationPresent && isUs && ssn && itin) {
    return {
      ssn: ERRORTYPE.ONLY_ONE,
      itin: ERRORTYPE.ONLY_ONE,
    };
  }

  return undefined;
};

export const validateMainResidencySsnAndItin = (values: TaxesFormData): MainResidencyErrorData | {} => {
  const isMainResidencyCA = get(values, 'isMainResidencyCA', false);
  const country = get(values, 'mainResidency.country', '');
  const province = get(values, 'mainResidency.province', '');
  let errors: TaxesErrorData = {};
  const residencyErrors = getResidencyErrors(get(values, 'mainResidency', {}));

  if (residencyErrors) {
    errors = residencyErrors;
  }

  if (province === '' && (isMainResidencyCA || isCountryUS(country))) {
    errors.province = ERRORTYPE.REQUIRED;
  }

  return Object.keys(errors).length ? { mainResidency: errors } : {};
};

export const validateSecondaryResidency = (values: TaxesFormData): SecondaryResidencyErrorData | undefined => {
  let errors: SecondaryResidencyErrorData = {};
  const secondaryResidency = get(values, 'secondaryResidency', []);
  const secondaryResidencyError: TaxesErrorData[] = [];
  const isMainResidencyCA: boolean = get(values, 'isMainResidencyCA', false);
  const mainResidencyCountry: string = get(values, 'mainResidency.country', '');
  const alreadySelectedCountry: string[] = [];

  if (mainResidencyCountry !== '') {
    alreadySelectedCountry.push(mainResidencyCountry);
  } else if (isMainResidencyCA) {
    alreadySelectedCountry.push(COUNTRIES.CA);
  }

  secondaryResidency.forEach((residency, index) => {
    const residencyErrors = getResidencyErrors(residency);
    const country: string = get(residency, 'country', '');

    if (country !== '' && alreadySelectedCountry.includes(country)) {
      secondaryResidencyError[index] = {
        ...residencyErrors,
        country: { type: 'addSecondaryResidency' },
      };
    } else if (country !== '') {
      alreadySelectedCountry.push(country);
    }

    if (residencyErrors) {
      secondaryResidencyError[index] = residencyErrors;
    }
  });

  if (secondaryResidencyError.length) {
    errors = {
      secondaryResidency: secondaryResidencyError,
    };
  }

  return errors;
};

const validation = (
  values: TaxesFormData,
): SINValidationData | MainResidencyErrorData | SecondaryResidencyErrorData => {
  return {
    ...validateSocialInsuranceNumber(values),
    ...validateMainResidencySsnAndItin(values),
    ...validateSecondaryResidency(values),
    ...(values.canChildrenBenInd === undefined ? { canChildrenBenInd: ERRORTYPE.REQUIRED } : {}),
  };
};

export default validation;
